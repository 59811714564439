<div id="page_content">
  <div id="page_content_inner">
    <h2 class="heading_b uk-margin-bottom uk-margin-large-top uk-text-center">Error: 403 Forbidden</h2>

    <!-- TOP HELP CONTENT -->
    <help-content #topHelperContent [position]="'top'"
                  [ngClass]="topHelperContent.isPresent()?'uk-margin-medium-top uk-margin-medium-bottom':'clear-style'">
    </help-content>

    <div class="uk-grid">

      <!-- LEFT HELP CONTENT -->
      <aside-help-content #leftHelperContent [position]="'left'"
                          [ngClass]="leftHelperContent.isPresent()?'tm-sidebar uk-width-1-4@m uk-first-column':'clear-style'">
      </aside-help-content>

      <!-- MIDDLE -->
      <div class=" uk-width-expand@m">

        <div style="font-size: 180px; color: #28beFF; line-height: 1.2;" class="uk-text-center">
          <strong>403</strong>
        </div>

        <div class="uk-text-center">
          Sorry, access to this resource on the server is forbidden.<br>
          Either check the URL or <a href="/home">go home</a>
        </div>

      </div>

      <!-- RIGHT HELP CONTENT -->
      <aside-help-content #rightHelperContent [position]="'right'"
                          [ngClass]="rightHelperContent.isPresent()?'tm-sidebar uk-width-1-4@m uk-first-column':'clear-style'">
      </aside-help-content>

    </div>

    <!-- BOTTOM HELP CONTENT -->
    <help-content #bottomHelperContent [position]="'bottom'"
                  [ngClass]="bottomHelperContent.isPresent()?'uk-margin-medium-top uk-margin-medium-bottom':'clear-style'">
    </help-content>

  </div>
</div>
