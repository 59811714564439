import { Component } from '@angular/core';

@Component ({
  selector: 'app-empty-page',
  templateUrl: './empty-page.component.html',
})

export class EmptyPageComponent {

  constructor() {}
}
