<form [formGroup]="group" class="uk-margin-medium-bottom">
  <div>
    <h4 class="uk-h4 uk-text-primary uk-scrollspy-inview uk-animation-slide-top-medium" uk-scrollspy-class="">
      Basic information
    </h4>
  </div>
  <div *ngIf="loadingMessage" class="loading-big">
    <div class="loader-big" style="text-align: center; padding-top: 170px; color: rgb(47, 64, 80); font-weight: bold;">
      {{ loadingMessage }}
    </div>
    <div class="whiteFilm"></div>
  </div>
  <div *ngIf="errorMessage" class="uk-alert uk-alert-danger">{{ errorMessage }}</div>
  <div *ngIf="successMessage" class="uk-alert uk-alert-success">{{ successMessage }}</div>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('softwarePlatform') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="softwarePlatformDesc" [valid]="group.get('softwarePlatform').valid">
          <select formControlName="softwarePlatform" class="md-input">
            <!--      <option value="" selected>[Other] (enter name below)</option> RESTORE AFTER getTypologies begins to work-->
            <option *ngFor="let platform of typologies" value="{{ platform.value }}">{{ platform.name }}</option>
          </select>
        </form-inline>
      </div>
    </div>
  </div>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{(group.get('platformName') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="platformNameDesc" [valid]="group.get('platformName').valid">
            <span *ngIf="group.get('platformName').touched && group.get('platformName').value"
                  class="help-block inline"
                  style="margin-top: 0px; margin-bottom: 0px; font-style: italic">This value will be used as the platform for your repository</span>
          <input formControlName="platformName" class="md-input" style="" type="text">
        </form-inline>
      </div>
    </div>
  </div>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('officialName') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="officialNameDesc" [valid]="group.get('officialName').valid">
          <input formControlName="officialName" class="md-input" style="" type="text">
        </form-inline>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'journal'">

    <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
      <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
        <div class="md-input-wrapper {{ (group.get('issn') != null) ? 'md-input-filled' : '' }}">
          <form-inline [description]="issnDesc" [valid]="group.get('issn').valid">
            <span *ngIf="group.get('issn').invalid && group.get('issn').touched && group.get('issn').dirty"
                  class="help-block inline uk-text-danger"
                  style="margin-top: 0px; margin-bottom: 0px; padding-left: 10px;">Issn needs to be of the form: "1111-1111" or "1111-111X"</span>
            <input formControlName="issn" class="md-input" type="text"
                   (focus)="group.get('issn').markAsUntouched()" (blur)="group.get('issn').updateValueAndValidity()">
          </form-inline>
        </div>
      </div>
    </div>

    <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
      <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
        <div class="md-input-wrapper {{ (group.get('eissn') != null) ? 'md-input-filled' : '' }}">
          <form-inline [description]="eissnDesc" [valid]="group.get('eissn').valid">
            <span *ngIf="group.get('eissn').invalid && group.get('eissn').touched && group.get('eissn').dirty"
                  class="help-block inline uk-text-danger"
                  style="margin-top: 0px; margin-bottom: 0px; padding-left: 10px;">Eissn needs to be of the form: "1111-1111" or "1111-111X"</span>
            <input formControlName="eissn" class="md-input" type="text"
                   (focus)="group.get('eissn').markAsUntouched()" (blur)="group.get('eissn').updateValueAndValidity()">
          </form-inline>
        </div>
      </div>
    </div>

    <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
      <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
        <div class="md-input-wrapper {{ (group.get('lissn') != null) ? 'md-input-filled' : '' }}">
          <form-inline [description]="lissnDesc" [valid]="group.get('lissn').valid">
            <span *ngIf="group.get('lissn').invalid && group.get('lissn').touched && group.get('lissn').dirty"
                  class="help-block inline uk-text-danger"
                  style="margin-top: 0px; margin-bottom: 0px; padding-left: 10px;">Lissn needs to be of the form: "1111-1111" or "1111-111X"</span>
            <input formControlName="lissn" class="md-input" type="text"
                   (focus)="group.get('lissn').markAsUntouched()" (blur)="group.get('lissn').updateValueAndValidity()">
          </form-inline>
        </div>
      </div>
    </div>

  </div>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('repoDescription') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="repoDescriptionDesc" [valid]="group.get('repoDescription').valid">
          <textarea formControlName="repoDescription" class="md-input"></textarea>
        </form-inline>
      </div>
    </div>
  </div>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('country') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="countryDesc" [valid]="group.get('country')">
          <select formControlName="country" class="md-input">
            <option value="">-- none selected --</option>
            <option *ngFor="let country of countries" value="{{country.code}}" title="{{country.name}}">{{ country.name }}</option>
          </select>
        </form-inline>
      </div>
    </div>
  </div>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('longtitude') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="longtitudeDesc" [valid]="group.get('longtitude').valid">
          <span *ngIf="group.get('longtitude').invalid && group.get('longtitude').touched && group.get('longtitude').dirty"
                class="help-block inline uk-text-danger"
                style="margin-top: 0px; margin-bottom: 0px; ">Invalid Longtitude</span>
          <input formControlName="longtitude" class="md-input" type="number" step="0.5"
                 (focus)="group.get('longtitude').markAsUntouched()"
                 (blur)="group.get('longtitude').updateValueAndValidity()">
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="longtitudeDesc" [valid]="group.get('longtitude').valid">-->
    <!--<span *ngIf="group.get('longtitude').invalid && group.get('longtitude').touched && group.get('longtitude').dirty"-->
          <!--class="help-block inline uk-text-danger"-->
          <!--style="margin-top: 0px; margin-bottom: 0px; ">Invalid Longtitude</span>-->
    <!--<input formControlName="longtitude" class="md-input" type="number" step="0.5"-->
           <!--(focus)="group.get('longtitude').markAsUntouched()"-->
           <!--(blur)="group.get('longtitude').updateValueAndValidity()">-->
  <!--</form-inline>-->

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('latitude') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="latitudeDesc" [valid]="group.get('latitude').valid">
          <span *ngIf="group.get('latitude').invalid && group.get('latitude').touched && group.get('latitude').dirty"
                class="help-block inline uk-text-danger"
                style="margin-top: 0px; margin-bottom: 0px; ">Invalid Latitude</span>
          <input formControlName="latitude" class="md-input" type="number" step="0.5"
                 (focus)="group.get('latitude').markAsUntouched()"
                 (blur)="group.get('latitude').updateValueAndValidity()">
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="latitudeDesc" [valid]="group.get('latitude').valid">-->
    <!--<span *ngIf="group.get('latitude').invalid && group.get('latitude').touched && group.get('latitude').dirty"-->
          <!--class="help-block inline uk-text-danger"-->
          <!--style="margin-top: 0px; margin-bottom: 0px; ">Invalid Latitude</span>-->
    <!--<input formControlName="latitude" class="md-input" type="number" step="0.5"-->
           <!--(focus)="group.get('latitude').markAsUntouched()"-->
           <!--(blur)="group.get('latitude').updateValueAndValidity()">-->
  <!--</form-inline>-->

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('websiteUrl') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="websiteUrlDesc" [valid]="group.get('websiteUrl').valid">
          <span *ngIf="group.get('websiteUrl').invalid && group.get('websiteUrl').touched && group.get('websiteUrl').dirty"
                class="help-block inline uk-text-danger"
                style="margin-top: 0px; margin-bottom: 0px; ">You need to enter a valid url</span>
          <input formControlName="websiteUrl" class="md-input" type="text"
                 (focus)="group.get('websiteUrl').markAsUntouched()"
                 (blur)="group.get('websiteUrl').updateValueAndValidity()">
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="websiteUrlDesc" [valid]="group.get('websiteUrl').valid">-->
    <!--<span *ngIf="group.get('websiteUrl').invalid && group.get('websiteUrl').touched && group.get('websiteUrl').dirty"-->
          <!--class="help-block inline uk-text-danger"-->
          <!--style="margin-top: 0px; margin-bottom: 0px; ">You need to enter a valid url</span>-->
    <!--<input formControlName="websiteUrl" class="md-input" type="text"-->
           <!--(focus)="group.get('websiteUrl').markAsUntouched()"-->
           <!--(blur)="group.get('websiteUrl').updateValueAndValidity()">-->
  <!--</form-inline>-->

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('institutionName') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="institutionNameDesc" [valid]="group.get('institutionName').valid">
          <input formControlName="institutionName" class="md-input" type="text">
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="institutionNameDesc" [valid]="group.get('institutionName').valid">-->
    <!--<input formControlName="institutionName" class="md-input" type="text">-->
  <!--</form-inline>-->

  <h4 class="uk-h4 uk-text-primary uk-scrollspy-inview uk-animation-slide-top-medium"
      uk-scrollspy-class="">Extra Information</h4>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('englishName') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="englishNameDesc" [valid]="group.get('englishName').valid">
          <input formControlName="englishName" class="md-input" type="text">
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="englishNameDesc" [valid]="group.get('englishName').valid">-->
    <!--<input formControlName="englishName" class="md-input" type="text" >-->
  <!--</form-inline>-->

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('logoUrl') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="logoUrlDesc" [valid]="group.get('logoUrl').valid">
        <span *ngIf="group.get('logoUrl').invalid && group.get('logoUrl').touched && group.get('logoUrl').dirty"
              class="help-block inline uk-text-danger"
              style="margin-top: 0px; margin-bottom: 0px; ">The url you entered is not valid</span>
          <input formControlName="logoUrl" class="md-input" type="text"
                 (focus)="group.get('logoUrl').markAsUntouched()"
                 (blur)="group.get('logoUrl').updateValueAndValidity()">
          <div class="comment fontItalic">
            Please make sure that the maximum size of the uploaded image is width=360px, height=240px
          </div>
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="logoUrlDesc" [valid]="group.get('logoUrl').valid">-->
    <!--<span *ngIf="group.get('logoUrl').invalid && group.get('logoUrl').touched && group.get('logoUrl').dirty"-->
          <!--class="help-block inline uk-text-danger"-->
          <!--style="margin-top: 0px; margin-bottom: 0px; ">The url you entered is not valid</span>-->
    <!--<input formControlName="logoUrl" class="md-input" type="text"-->
           <!--(focus)="group.get('logoUrl').markAsUntouched()"-->
           <!--(blur)="group.get('logoUrl').updateValueAndValidity()">-->
    <!--<div class="comment fontItalic">-->
      <!--Please make sure that the maximum size of the uploaded image is width=360px, height=240px-->
    <!--</div>-->
  <!--</form-inline>-->

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('timezone') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="timezoneDesc" [valid]="group.get('timezone').valid">
          <select formControlName="timezone" class="md-input">
            <option value="">-- none selected --</option>
            <option *ngFor="let timezone of timezones" value="{{ timezone.offset }}">{{ timezone.name }}</option>
          </select>
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="timezoneDesc" [valid]="group.get('timezone').valid">-->
    <!--<select formControlName="timezone" class="md-input">-->
      <!--<option value="">&#45;&#45; none selected &#45;&#45;</option>-->
      <!--<option *ngFor="let timezone of timezones" value="{{ timezone.offset }}">{{ timezone.name }}</option>-->
    <!--</select>-->
  <!--</form-inline>-->

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('datasourceType') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="datasourceTypeDesc" [valid]="group.get('datasourceType').valid">
          <select formControlName="datasourceType" class="md-input">
            <option value="">-- none selected --</option>
            <option *ngFor="let key of classCodes" value="{{key}}">{{ datasourceClasses[key] }}</option>
          </select>
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="datasourceTypeDesc" [valid]="group.get('datasourceType').valid">-->
    <!--<select formControlName="datasourceType" class="md-input">-->
      <!--<option value="">&#45;&#45; none selected &#45;&#45;</option>-->
      <!--<option *ngFor="let key of classCodes" value="{{key}}">{{ datasourceClasses[key] }}</option>-->
    <!--</select>-->
  <!--</form-inline>-->

  <h4 class="uk-h4 uk-text-primary uk-scrollspy-inview uk-animation-slide-top-medium"
      uk-scrollspy-class="">Administrator & contact information</h4>

  <div data-dynamic-fields="d_field_wizard" class="uk-grid" data-uk-grid-margin="" dynamic-fields-counter="0">
    <div class="uk-width-medium-1-1 parsley-row form_section uk-row-first">
      <div class="md-input-wrapper {{ (group.get('adminEmail') != null) ? 'md-input-filled' : '' }}">
        <form-inline [description]="adminEmailDesc" [valid]="group.get('adminEmail').valid">
          <span *ngIf="group.get('adminEmail').invalid && group.get('adminEmail').touched && group.get('adminEmail').dirty"
                class="help-block inline"
                style="margin-top: 0px; margin-bottom: 0px; ">You need to enter a valid email address</span>
          <input formControlName="adminEmail" class="md-input" type="text"
                 (focus)="group.get('adminEmail').markAsUntouched()"
                 (blur)="group.get('adminEmail').updateValueAndValidity()">
        </form-inline>
      </div>
    </div>
  </div>

  <!--<form-inline [description]="adminEmailDesc" [valid]="group.get('adminEmail').valid">-->
    <!--<span *ngIf="group.get('adminEmail').invalid && group.get('adminEmail').touched && group.get('adminEmail').dirty"-->
          <!--class="help-block inline"-->
          <!--style="margin-top: 0px; margin-bottom: 0px; ">You need to enter a valid email address</span>-->
    <!--<input formControlName="adminEmail" class="md-input" type="text"-->
           <!--(focus)="group.get('adminEmail').markAsUntouched()"-->
           <!--(blur)="group.get('adminEmail').updateValueAndValidity()">-->
  <!--</form-inline>-->

</form>
