<div>
  <div *ngIf="loadingMessage" class="loading-big">

    <div class="md-class-content">
      <div class="loader-big" style="text-align: center; padding-top: 170px; color: rgb(47, 64, 80); font-weight: bold;">
        {{ loadingMessage }}
      </div>
      <div class="whiteFilm"></div>
    </div>

  </div>
  <div *ngIf="repoInterfaceForm && (!loadingMessage || (loadingMessage===''))" [formGroup]="repoInterfaceForm">

    <div class="md-card-toolbar">
      <div class="md-card-toolbar-actions">
        <a *ngIf="!inRegister" (click)="saveInterface()" class="uk-margin-small-right"><i class="md-icon material-icons">save</i></a>
        <a (click)="removeInterface()" class="uk-margin-small-left"><i class="md-icon material-icons">clear</i></a>
      </div>
      <!--<h3 class="md-card-toolbar-heading-text"> Card Heading </h3>-->
    </div>

    <!--<div class="md-card-toolbar interfaceActionsPanel">-->
      <!--<a *ngIf="!inRegister" (click)="saveInterface()" class="uk-margin-small-right"><i class="far fa-save fa-lg"></i></a>-->
      <!--<a (click)="removeInterface()" class="uk-margin-small-left"><i class="fas fa-times fa-lg"></i></a>-->
    <!--</div>-->

    <div class="md-card-content">
      <div *ngIf="successMessage" class="uk-alert uk-alert-success" style="clear: both">{{ successMessage }}</div>
      <div *ngIf="errorMessage" class="uk-alert" [ngClass]="{'uk-alert-warning': errorMessage == invalidCustomBaseUrl,
                'uk-alert-danger': errorMessage != invalidCustomBaseUrl}" style="clear: both">{{ errorMessage }}</div>

      <div class="md-input-wrapper uk-margin-medium-top {{ (repoInterfaceForm.get('baseUrl') != null) ? 'md-input-filled' : '' }}">
        <label class="" for="baseUrl" title="{{ baseUrlDesc.desc }}">Base OAI-PMH URL (*)</label>
        <span *ngIf="showIdentifiedBaseUrl" class="help-block inline" style="margin-top: 8px; margin-bottom: 0px; padding-left: 10px; display: block;">
          Identified
        </span>
        <input id="baseUrl" type="text" class="md-input" formControlName="baseUrl" (blur)="getInterfaceInfo()">
        <span class="md-input-bar"></span>
      </div>


      <!--<div class="form-group has-success">-->
        <!--<label class="control-label" for="baseUrl" title="{{ baseUrlDesc.desc }}">Base OAI-PMH URL (*)</label>-->
        <!--<span *ngIf="identifiedBaseUrl" class="help-block inline" style="margin-top: 0px; margin-bottom: 0px; padding-left: 10px;">-->
          <!--Identified-->
        <!--</span>-->
        <!--<input id="baseUrl" type="text" class="form-control" formControlName="baseUrl" (blur)="getInterfaceInfo()">-->
      <!--</div>-->


      <div class="radioButtonForm uk-margin-top">
        <label class="control-label">Validation Set</label>

        <div class="form-group">
          <label for="selectRadio{{interfaceID}}" title="{{ existingValSetDesc.desc }}"
                 class="uk-button uk-link-muted visible_uk_links"
                 style="display: block; text-align: left;">
            <input id="selectRadio{{interfaceID}}" value="select" name="validationSet{{interfaceID}}" type="radio"
                   (change)="chooseValSet(true)" checked >
            <span class="uk-margin-small-left">Choose existing</span>
          </label>
        </div>
        <div class="md-input-wrapper">
          <!--<label class="">Select repository's country</label>-->
          <select class="md-input" id="selectValidationSet{{interfaceID}}" formControlName="selectValidationSet" (change)="checkIfValid()">
            <option value="" selected>-- none selected --</option>
            <option *ngFor="let set of valsetList" value="{{set}}">{{set}}</option>
          </select>
          <span class="md-input-bar"></span>
        </div>
        <div class="form-group">
          <label for="customRadio{{interfaceID}}" title="{{ customValSetDesc.desc }}"
                 class="uk-button uk-link-muted visible_uk_links"
                 style="display: block; text-align: left;">
            <input id="customRadio{{interfaceID}}" value="custom" name="validationSet{{interfaceID}}" type="radio"
                   (change)="chooseValSet(false)">
            <span class="uk-margin-small-left">or a custom one</span>
          </label>
        </div>
        <div class="md-input-wrapper">
          <input id="customValidationSet{{interfaceID}}" formControlName="customValidationSet"
                 class="md-input" type="text" (keyup)="checkIfValid()">
          <span class="md-input-bar"></span>
        </div>

      </div>


      <!--<div class="form-group">-->
        <!--<label class="control-label">Validation Set</label>-->
        <!--<div>-->
          <!--<label class="uk-button validationSetRadio" for="selectRadio{{interfaceID}}" title="{{ existingValSetDesc.desc }}">-->
            <!--<input id="selectRadio{{interfaceID}}" value="select" name="validationSet{{interfaceID}}" type="radio"-->
                   <!--(change)="chooseValSet(true)" checked >-->
            <!--<span class="uk-margin-small-left">Choose existing</span>-->
          <!--</label>-->
        <!--</div>-->
        <!--<select id="selectValidationSet{{interfaceID}}" formControlName="selectValidationSet"-->
                <!--class="form-control" (change)="checkIfValid()">-->
          <!--<option value="" selected>&#45;&#45; none selected &#45;&#45;</option>-->
          <!--<option *ngFor="let set of valsetList" value="{{set}}">{{set}}</option>-->
        <!--</select>-->
        <!--<div>-->
          <!--<label class="uk-button validationSetRadio" for="customRadio{{interfaceID}}" title="{{ customValSetDesc.desc }}">-->
            <!--<input id="customRadio{{interfaceID}}" value="custom" name="validationSet{{interfaceID}}" type="radio"-->
                   <!--(change)="chooseValSet(false)">-->
            <!--<span class="uk-margin-small-left">or a custom one</span>-->
          <!--</label>-->
        <!--</div>-->
        <!--<input id="customValidationSet{{interfaceID}}" formControlName="customValidationSet"-->
               <!--class="form-control" type="text" (keyup)="checkIfValid()">-->
      <!--</div>-->

      <div class="md-input-wrapper md-input-filled uk-margin-medium-top">
        <label style="top: -16px" class="" for="compLvl" title="{{ compatibilityLevelDesc.desc }}">Desired Compatibility Level (*)</label>
        <select class="md-input" id="compLvl" formControlName="compatibilityLevel" (change)="checkIfValid()">
          <option value="">-- none selected --</option>
          <option *ngFor="let key of classCodes" value="{{key}}">{{compClasses[key]}}</option>
        </select>
        <span class="md-input-bar"></span>
      </div>

      <!--<div class="form-group">-->
        <!--<label class="control-label" for="compLvl" title="{{ compatibilityLevelDesc.desc }}">Desired Compatibility Level (*)</label>-->
        <!--<select class="form-control" id="compLvl" formControlName="compatibilityLevel" (change)="checkIfValid()">-->
          <!--<option value="">&#45;&#45; none selected &#45;&#45;</option>-->
          <!--<option *ngFor="let key of classCodes" value="{{key}}">{{compClasses[key]}}</option>-->
        <!--</select>-->
      <!--</div>-->

      <div class="">
        <label class="">Current Compatibility Level</label>
        <div *ngIf="existingCompLevel">{{ existingCompLevel ? existingCompLevel : 'not available' }}</div>
      </div>

      <!--<div>-->
        <!--<label class="uk-form-controls-text control-label">Current Compatibility Level</label>-->
        <!--<div *ngIf="existingCompLevel">{{ existingCompLevel ? existingCompLevel : 'not available' }}</div>-->
      <!--</div>-->

      <div class="uk-margin-medium-top">
        <label class="" for="comment" title="{{ commentDesc.desc }}">Comments (What else do we need to know?)</label>
        <textarea id="comment" class="uk-textarea" rows="3" formControlName="comment" (blur)="checkIfValid()"></textarea>
      </div>

    </div>

  </div>
</div>
