<!--MOBILE & PAD PORTRAIT-->
<div class="uk-hidden@m">
  <div class="provideLanding">
    <div class="image-front-topbar  uk-section-default"
         uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-fade&quot;,&quot;delay&quot;:false}" tm-header-transparent="light">
      <!--<div style="background-image: url('../../../../assets/imgs/landing/Group 1149.svg'); background-color: rgb(255, 255, 255); box-sizing: border-box; min-height: calc(-101.35px + 100vh);"-->
      <!--<div style="background-image: url('../../../../assets/imgs/landing/Group 1149.svg'); background-color: rgb(255, 255, 255); box-sizing: border-box; min-height: calc(-101.35px + 100vh);"-->
      <div style="background: transparent linear-gradient(0deg, #F7F7F7 20%, #E2EEFA 30%) 0% 0% no-repeat padding-box; box-sizing: border-box; min-height: calc(-101.35px + 100vh);"
           class="uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-padding-remove-bottom uk-flex uk-flex-middle" uk-height-viewport="offset-top: true;offset-bottom: 20">
        <div class="uk-width-1-1">
          <!--<div class="uk-margin-medium uk-grid uk-grid-stack" uk-grid="">-->
            <!--<div class="uk-width-1-1@m">-->
            <!--</div>-->
          <!--</div>-->
          <div class="uk-container uk-container-large uk-margin-large">
            <!--<div class="uk-sticky-placeholder" style="height: 84px; margin: 0px;"></div>-->
            <div class="search_box_bg uk-grid-large uk-flex-middle uk-grid uk-grid-stack" uk-grid="">
              <div class="el-overlay uk-panel uk-width-1-1 uk-dark uk-text-center">

                <h1 class="uk-margin">Your data is valuable. Get connected. Participate</h1>

                <div class="uk-container uk-container-xsmall uk-container-center">
                  <div class="uk-margin-medium-top uk-margin-medium-left uk-margin-medium-right text-muted">
                    The Provide Dashboard is a one-stop-service where content providers interact
                    with OpenAIRE and become a building block of a global Open Research community. A gateway to the European Open Science Cloud.
                  </div>
                </div>

                <div class="uk-container uk-container-small uk-container-center">

                  <div class="uk-margin-left uk-margin-right uk-margin-top">
                    <div class="uk-grid uk-child-width-1-3">
                      <div class="uk-width-1-3">
                        <img src="../../../../assets/imgs/landing/paidaki_me_xartia.png">
                      </div>
                      <div class="uk-width-1-3" style="display: flex; align-items: center; justify-content: center">
                        <!--fixme remove custom padding??-->
                        <a style="padding: 0 17px" (click)="goToPage('/join')" class="el-link uk-button uk-button-primary">SIGN IN</a>
                      </div>
                    </div>
                  </div>

                </div>

                <!--<div class="el-content uk-margin"><h2 style="color:#1a1a1a!important">A one-stop-shop for sharing, finding and enriching your content</h2></div>-->
                <!--<p><a (click)="goToPage('/dashboard')" class="el-link uk-button uk-button-primary">Sign In</a></p>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section-muted uk-section uk-section-large uk-section-overlap uk-position-relative">

      <h2 class="uk-text-center">PROVIDE Dashboard brings all your data together</h2>

      <div class="uk-margin-medium-left uk-margin-medium-right uk-text-center text-muted">Get front-end access to many of OpenAIRE’s backend services</div>

      <div class="uk-container uk-container-large uk-margin-medium-top">

        <div class="slider-mobile-card">
          <div class="uk-slider" tabindex="-1" uk-slider="velocity: 0;autoplay: true;autoplay-interval: 5000;pause-on-hover: false;center: true">
            <div class="uk-position-relative">
              <div class="uk-slider-container">
                <ul class="uk-slider-items uk-child-width-1-1" style="transform: translateX(-1300px);">
                  <li class="ng-star-inserted uk-active" tabindex="-1" style="order: 1;">
                    <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                      <div class="uk-first-column">
                        <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                          <source src="../../../../assets/mp4/validate.mp4" type="video/mp4">
                        </video>
                        <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/validate.gif">-->
                      </div>
                      <div class="uk-margin-top">
                        <div>
                          <div class="uk-text-bold uk-h4">Validate</div>
                          <div class="uk-margin-medium">
                            Interoperable metadata is key for effective content sharing.
                            <p class="uk-margin-top">
                              OpenAIRE guidelines are a global standard to expose and share your content. Use our validation service and see how you can apply them.
                            </p>
                          </div>
                          <div class="uk-inline">
                            <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li tabindex="-1" style="order: 1;">
                    <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                      <div class="uk-first-column">
                        <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                          <source src="../../../../assets/mp4/register.mp4" type="video/mp4">
                        </video>
                        <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/register.gif">-->
                      </div>
                      <div class="uk-margin-top">
                        <div>
                          <div class="uk-text-bold uk-h4">Register</div>
                          <div class="uk-margin-medium">
                            Reach a wider audience around the world.
                            <p class="uk-margin-top">
                              Register your literature or data repository, OA Journal, CRIS in OpenAIRE and be part of a global interlinked network.
                            </p>
                          </div>
                          <div class="uk-inline">
                            <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li tabindex="-1" style="order: 1;">
                    <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                      <div class="uk-first-column">
                        <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                          <source src="../../../../assets/mp4/enrich.mp4" type="video/mp4">
                        </video>
                        <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/enrichments.gif">-->
                      </div>
                      <div class="uk-margin-top">
                        <div>
                          <div class="uk-text-bold uk-h4">Enrich</div>
                          <div class="uk-margin-medium">
                            Improve your metadata. Get more connections.
                            <p class="uk-margin-top">
                              Our newly released OA Broker service offers a wealth of information on scholarly communication data. Find out what interests you and subscribe to enrich your records.
                            </p>
                          </div>
                          <div class="uk-inline">
                            <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li tabindex="-1" style="order: 1;">
                    <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                      <div class="uk-first-column">
                        <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                          <source src="../../../../assets/mp4/usage-stat.mp4" type="video/mp4">
                        </video>
                        <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/usageStats.gif">-->
                      </div>
                      <div class="uk-margin-top">
                        <div>
                          <div class="uk-text-bold uk-h4">Usage statistics</div>
                          <div class="uk-margin-medium">
                            Open research impact empowers Open Science.
                            <p class="uk-margin-top">
                              Share usage data via OpenAIRE's global Open Metrics Service. Get the benefit of an aggregated environment to broaden the mechanisms for impact assessment.
                            </p>
                          </div>
                          <div class="uk-inline">
                            <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <ul class="uk-position-relative uk-slider-nav uk-dotnav uk-flex-center uk-margin">
              <li uk-slider-item="0" class="uk-active"><a href="#"></a></li>
              <li uk-slider-item="1" class=""><a href="#"></a></li>
              <li uk-slider-item="2" class=""><a href="#"></a></li>
              <li uk-slider-item="3" class=""><a href="#"></a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <div class="uk-section uk-section-muted uk-section-large uk-preserve-color uk-background-norepeat uk-background-cover uk-background-top-center"
         style="background-image: url('../../../../assets/imgs/landing/abstract2.svg'); !important; min-height: calc(80vh);" uk-height-viewport="offset-top: true; offset-bottom: 20;">
      <div class="uk-container uk-container-expand">

        <h1 class="uk-text-center">Do numbers make you happy?</h1>

        <div class="uk-margin-medium-top">
          <div class="" style="display: block; width: 60%; margin-left: auto; margin-right: auto">
            <img src="../../../../assets/imgs/landing/avakas.png">
          </div>
        </div>

        <div class="uk-grid">
          <div class="uk-width-expand">

            <div class="uk-grid uk-child-width-1-2 uk-text-center" style="display: flex;align-items: center;height: 70%;">

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.literature) ? (statisticsNumbers.literature | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">Literature repositories</div>
              </div>

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.journal) ? (statisticsNumbers.journal | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">Data repositories</div>
              </div>

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.dataRepositories) ? (statisticsNumbers.dataRepositories | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">OA journals</div>
              </div>

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.aggregators) ? (statisticsNumbers.aggregators | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">Aggregators</div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!--<div class="uk-container uk-container-large uk-margin-top">-->
        <!--<div class="uk-grid uk-child-width-1-5@l uk-child-width-1-5@m uk-child-width-1-2@s uk-margin-medium-left">-->

          <!--<div class="uk-margin-top">-->
            <!--<h3>{{ (statisticsNumbers && statisticsNumbers.publications) ? (statisticsNumbers.publications | number) : 'N/A' }}</h3>-->
            <!--<div class="uk-text-uppercase text-small iconsWithText">-->
              <!--<span><i class="fas fa-book fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>-->
              <!--<span>publications</span>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="uk-margin-top">-->
            <!--<h3>{{ (statisticsNumbers && statisticsNumbers.datasets) ? (statisticsNumbers.datasets | number) : 'N/A' }}</h3>-->
            <!--<div class="uk-text-uppercase text-small iconsWithText">-->
              <!--<span><i class="fas fa-database fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>-->
              <!--<span>datasets</span>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="uk-margin-top">-->
            <!--<h3>{{ (statisticsNumbers && statisticsNumbers.software) ? (statisticsNumbers.software | number) : 'N/A' }}</h3>-->
            <!--<div class="uk-text-uppercase text-small iconsWithText">-->
              <!--<span><i class="fas fa-cog fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>-->
              <!--<span>software</span>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="uk-margin-top">-->
            <!--<h3>{{ (statisticsNumbers && statisticsNumbers.usagestats) ? statisticsNumbers.usagestats : 'N/A' }}</h3>-->
            <!--<div class="uk-text-uppercase text-small iconsWithText">-->
              <!--<span><i class="fas fa-globe-americas fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>-->
              <!--<span>metadata exchange events</span>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="uk-margin-top">-->
            <!--<h3>{{ statisticsNumbers ? (statisticsNumbers.lastYearUsagestats.number | number) : 'N/A' }}</h3>-->
            <!--<div class="uk-text-uppercase text-small iconsWithText">-->
              <!--<span><i class="fas fa-globe-americas fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>-->
              <!--<span>usage statistics views & downloads ({{ statisticsNumbers ? statisticsNumbers.lastYearUsagestats.year : 'N/A' }})</span>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    </div>

    <!--<div class="uk-section uk-section-muted uk-section-large text-small" style="background: transparent url('../../../../assets/imgs/landing/kathisto%20paidaki.png') 0% 0% no-repeat padding-box;">-->
    <div class="uk-section uk-section-muted uk-padding-remove-top text-small">

      <div style="position: relative; top: 25px; text-align: right;right: 33px;">
        <img src="../../../../assets/imgs/landing/kathisto%20paidaki.png" width="58">
      </div>


      <div class="uk-container uk-container-xsmall uk-margin-left uk-margin-right openAIREGuidelinesBoxMobile">
        <div>

          <div class="uk-margin-medium-top">
            <h5 class="uk-text-uppercase uk-text-center">
              <span><strong>Follow OpenAIRE Guidelines</strong></span>
            </h5>
          </div>

          <div style="">
            <div class="uk-grid uk-child-width-1-1">
              <div class="uk-margin-medium-bottom uk-margin-medium-top">
                <div style="border-bottom: 1px solid #BFBFBF; padding-bottom: 20px" class="uk-margin-left uk-margin-right">
                  <div style="display: block; text-align: center">
                    <img width="56" src="../../../../assets/imgs/landing/Guidelines%20color.svg">
                  </div>
                  <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">OpenAIRE Guidelines</div>
                  <p>Follow the OpenAIRE <strong>Guidelines</strong> to expose your metadata in order to integrate with OpenAIRE infrastructure. <br>Learn more:
                    <a href="https://guidelines.openaire.eu/en/latest/" target="_blank">https://guidelines.openaire.eu/en/latest/</a></p>
                  <p>Follow the OpenAIRE <strong>Usage Statistics Guidelines</strong> to get ready to track the usage activity of your data source. <br>Learn more:
                    <a href="https://openaire.github.io/usage-statistics-guidelines/" target="_blank">https://openaire.github.io/usage-statistics-guidelines/</a></p>
                </div>

              </div>

              <div class="uk-margin-medium-bottom">
                <div style="border-bottom: 1px solid #BFBFBF; padding-bottom: 20px" class="uk-margin-left uk-margin-right">
                  <div style="display: block; text-align: center">
                    <img width="43" src="../../../../assets/imgs/landing/Data%20color.svg">
                  </div>
                  <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Our Data Policies</div>
                  <p>
                    <strong>Data Acquisition</strong> - How we retrieve data, how often, what processes it goes through (aggregating cleaning, transforming, inferring, de-duplicating), what are the quality checks along all data processing stages.
                  </p>
                  <p>
                    <strong>Data usage</strong> - Who is able to retrieve our data, what are the licences, what about specific SLAs. <br>Learn more:
                    <a href="https://www.openaire.eu/content-aquisition-policy" target="_blank">https://www.openaire.eu/content-aquisition-policy</a>
                  </p>
                </div>

              </div>

            </div>
          </div>


          <div class="uk-grid uk-child-width-1-1">
            <div class="uk-margin-medium-bottom">
              <div style="border-bottom: 1px solid #BFBFBF; padding-bottom: 20px" class="uk-margin-left uk-margin-right">
                <div style="display: block; text-align: center">
                  <img width="55" src="../../../../assets/imgs/landing/Agreement%20color.svg">
                </div>
                <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Terms of Agreement for Content Providers</div>
                <p>Learn more: <a href="https://www.openaire.eu/terms-of-use-for-content-providers" target="_blank">https://www.openaire.eu/terms-of-use-for-content-providers</a></p>
              </div>

            </div>

            <div class="uk-margin-medium-bottom">
              <div class="uk-margin-left uk-margin-right">
                <div style="display: block; text-align: center">
                  <img width="38" src="../../../../assets/imgs/landing/Support%20color.svg">
                </div>
                <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Support</div>
                <p>Read <strong>how to use OpenAIRE</strong> to best serve your needs. <br>Learn more: <a href="https://www.openaire.eu/guides" target="_blank">https://www.openaire.eu/guides</a></p>
                <p>Ask a question. Contact us via our <strong>ticketing system.</strong> <br>Learn more: <a href="https://www.openaire.eu/support/helpdesk" target="_blank">https://www.openaire.eu/support/helpdesk</a></p>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="uk-section uk-section-muted uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-flex uk-flex-middle"
         style="background: transparent linear-gradient(0deg, #E2EEFA 20%, #F7F7F7 70%) 0% 0% no-repeat padding-box; box-sizing: border-box;">
      <!--<div style="background: transparent linear-gradient(0deg, #E2EEFA 20%, #F7F7F7 30%) 0% 0% no-repeat padding-box; box-sizing: border-box;"-->
      <!--class="uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-padding-remove-bottom uk-flex uk-flex-middle">-->
      <!--</div>-->
      <div class="uk-container uk-container-xsmall uk-container-center uk-margin-left uk-margin-right">
        <div class="uk-grid uk-flex" style="display: block; text-align: center">
          <div class="">
            <h2>We're here to help you.</h2>
            <p class="text-muted">Do you want more information? Our team is happy to assist you.</p>
            <a class="uk-margin-large-top uk-text-uppercase uk-button uk-button-primary" href="https://www.openaire.eu/contact-us/" target="_blank">Get in touch</a>
          </div>
          <div class="uk-margin-top">
            <img src="../../../../assets/imgs/landing/contact%20us%20provide.png" width="66">
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section uk-section-muted" uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-fade&quot;,&quot;delay&quot;:false}">
      <div class="uk-container uk-container-large">
        <div class="uk-grid uk-margin-large-top uk-margin-large-bottom" uk-grid="">
          <div class="uk-width-1-1 uk-width-1-2@s uk-dark uk-grid-item-match uk-first-column explore">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Researcher?
              </h4>
              <div class="el-content uk-margin">
                Explore all OA research results. Link all your research. Build your profile
              </div>
              <p>
                <a href="https://{{ inBeta ? 'beta.' : '' }}explore.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.EXPLORE
                </a>
              </p>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-2@s uk-dark uk-grid-item-match connect">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Research community?
              </h4>
              <div class="el-content uk-margin">
                Use a trusted partner to share, link, disseminate and monitor your research.
              </div>
              <p>
                <a href="https://{{ inBeta ? 'beta.' : '' }}connect.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.CONNECT
                </a>
              </p>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-2@s uk-dark uk-grid-item-match monitor">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Research manager?
              </h4>
              <div class="el-content uk-margin">
                Use our monitoring services and easily track all relevant research results.
              </div>
              <p>
                <a href="https://monitor.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.MONITOR
                </a>
              </p>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-2@s uk-dark uk-grid-item-match develop">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Developer?
              </h4>
              <div class="el-content uk-margin">
                Get access to OpenAIRE data and capitalize on on Europe's open linked research
              </div>
              <p>
                <a href="https://develop.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.DEVELOP
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<!--LAPTOP & PAD LANDSCAPE-->
<div class="uk-visible@m">
  <div class="provideLanding">
    <div class="image-front-topbar  uk-section-default"
         uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-fade&quot;,&quot;delay&quot;:false}" tm-header-transparent="light">
      <!--<div style="background-image: url('../../../../assets/imgs/landing/Group 1149.svg'); background-color: rgb(255, 255, 255); box-sizing: border-box; min-height: calc(-101.35px + 100vh);"-->
      <!--<div style="background-image: url('../../../../assets/imgs/landing/Group 1149.svg'); background-color: rgb(255, 255, 255); box-sizing: border-box; min-height: calc(-101.35px + 100vh);"-->
      <div style="background: transparent linear-gradient(0deg, #F7F7F7 20%, #E2EEFA 30%) 0% 0% no-repeat padding-box; box-sizing: border-box; min-height: calc(-101.35px + 100vh);"
           class="uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-padding-remove-bottom uk-flex uk-flex-middle" uk-height-viewport="offset-top: true;offset-bottom: 20">
        <div class="uk-width-1-1">
          <div class="uk-margin-medium uk-grid uk-grid-stack" uk-grid="">
            <div class="uk-width-1-1@m">
            </div>
          </div>
          <div class="uk-container uk-container-large uk-margin-large">
            <div class="uk-sticky-placeholder" style="height: 84px; margin: 0px;"></div>
            <div class="search_box_bg uk-grid-large uk-flex-middle uk-grid uk-grid-stack" uk-grid="">
              <div class="el-overlay uk-panel uk-width-1-1 uk-dark uk-text-center">

                <h1 class="uk-margin">Your data is valuable. Get connected. Participate</h1>

                <div class="uk-container uk-container-xsmall uk-container-center">
                  <div class="uk-margin-medium-top uk-margin-medium-left uk-margin-medium-right text-muted">
                    The Provide Dashboard is a one-stop-service where content providers interact
                    with OpenAIRE and become a building block of a global Open Research community. A gateway to the European Open Science Cloud.
                  </div>
                </div>

                <div class="uk-container uk-container-large uk-container-center">

                  <div class="uk-margin-large-left uk-margin-large-right uk-margin-top">
                    <div class="uk-grid uk-child-width-1-3@l uk-child-width-1-3@m uk-child-width-1-3@s">
                      <div class="">
                        <img src="../../../../assets/imgs/landing/paidaki_me_xartia.png">
                      </div>
                      <div style="display: flex; align-items: center; justify-content: center">
                        <a (click)="goToPage('/join')" class="el-link uk-button uk-button-primary">SIGN IN</a>
                      </div>
                    </div>
                  </div>

                </div>

                <!--<div class="el-content uk-margin"><h2 style="color:#1a1a1a!important">A one-stop-shop for sharing, finding and enriching your content</h2></div>-->
                <!--<p><a (click)="goToPage('/dashboard')" class="el-link uk-button uk-button-primary">Sign In</a></p>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section-muted uk-section uk-section-large uk-section-overlap uk-position-relative">

      <h2 class="uk-text-center">PROVIDE Dashboard brings all your data together</h2>

      <div class="uk-margin-medium-left uk-margin-medium-right uk-text-center text-muted">Get front-end access to many of OpenAIRE’s backend services</div>

      <div class="uk-container uk-container-large uk-margin-medium-top">

        <div class="uk-slider" tabindex="-1" uk-slider="velocity: 0;autoplay: true;autoplay-interval: 5000;pause-on-hover: false;center: true">
          <div class="uk-position-relative">
            <div class="uk-slider-container">
              <ul class="uk-slider-items uk-child-width-1-1" style="transform: translateX(-1300px);">
                <li class="ng-star-inserted uk-active" tabindex="-1" style="order: 1;">
                  <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                    <div class="uk-first-column">
                      <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                        <source src="../../../../assets/mp4/validate.mp4" type="video/mp4">
                      </video>
                      <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/validate.gif">-->
                    </div>
                    <div class="uk-margin-top">
                      <div>
                        <div class="uk-text-bold uk-h4">Validate</div>
                        <div class="uk-margin-medium">
                          Interoperable metadata is key for effective content sharing.
                          <p class="uk-margin-top">
                            OpenAIRE guidelines are a global standard to expose and share your content. Use our validation service and see how you can apply them.
                          </p>
                        </div>
                        <div class="uk-inline">
                          <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li tabindex="-1" style="order: 1;">
                  <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                    <div class="uk-first-column">
                      <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                        <source src="../../../../assets/mp4/register.mp4" type="video/mp4">
                      </video>
                      <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/register.gif">-->
                    </div>
                    <div class="uk-margin-top">
                      <div>
                        <div class="uk-text-bold uk-h4">Register</div>
                        <div class="uk-margin-medium">
                          Reach a wider audience around the world.
                          <p class="uk-margin-top">
                            Register your literature or data repository, OA Journal, CRIS in OpenAIRE and be part of a global interlinked network.
                          </p>
                        </div>
                        <div class="uk-inline">
                          <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li tabindex="-1" style="order: 1;">
                  <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                    <div class="uk-first-column">
                      <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                        <source src="../../../../assets/mp4/enrich.mp4" type="video/mp4">
                      </video>
                      <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/enrichments.gif">-->
                    </div>
                    <div class="uk-margin-top">
                      <div>
                        <div class="uk-text-bold uk-h4">Enrich</div>
                        <div class="uk-margin-medium">
                          Improve your metadata. Get more connections.
                          <p class="uk-margin-top">
                            Our newly released OA Broker service offers a wealth of information on scholarly communication data. Find out what interests you and subscribe to enrich your records.
                          </p>
                        </div>
                        <div class="uk-inline">
                          <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li tabindex="-1" style="order: 1;">
                  <div class="uk-flex uk-padding uk-child-width-1-2@m uk-child-width-1-1@s uk-grid" uk-grid="">
                    <div class="uk-first-column">
                      <video class="uk-box-shadow-large uk-border-rounded" autoplay loop>
                        <source src="../../../../assets/mp4/usage-stat.mp4" type="video/mp4">
                      </video>
                      <!--<img class="uk-box-shadow-large uk-border-rounded" src="../../../../assets/gifs/usageStats.gif">-->
                    </div>
                    <div class="uk-margin-top">
                      <div>
                        <div class="uk-text-bold uk-h4">Usage statistics</div>
                        <div class="uk-margin-medium">
                          Open research impact empowers Open Science.
                          <p class="uk-margin-top">
                            Share usage data via OpenAIRE's global Open Metrics Service. Get the benefit of an aggregated environment to broaden the mechanisms for impact assessment.
                          </p>
                        </div>
                        <div class="uk-inline">
                          <a class="uk-button uk-button-primary uk-text-uppercase" [routerLink]="['/about']">learn more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <ul class="uk-position-relative uk-slider-nav uk-dotnav uk-flex-center uk-margin">
            <li uk-slider-item="0" class="uk-active"><a href="#"></a></li>
            <li uk-slider-item="1" class=""><a href="#"></a></li>
            <li uk-slider-item="2" class=""><a href="#"></a></li>
            <li uk-slider-item="3" class=""><a href="#"></a></li>
          </ul>
        </div>

      </div>
    </div>

    <div class="uk-section uk-section-muted uk-section-large uk-preserve-color uk-background-norepeat uk-background-cover uk-background-top-center"
         style="background-image: url('../../../../assets/imgs/landing/abstract2.svg'); !important; min-height: calc(80vh);" uk-height-viewport="offset-top: true; offset-bottom: 20;">
      <div class="uk-container uk-container-expand">

        <div class="uk-grid uk-child-width-1-3@l uk-child-width-1-3@m">
          <div>
            <img src="../../../../assets/imgs/landing/avakas.png">
          </div>
          <div class="uk-width-expand">
            <h1 class="uk-text-center">Do numbers make you happy?</h1>

            <div class="uk-grid uk-child-width-1-4@l uk-child-width-1-4@m uk-child-width-1-1@s uk-margin-medium-left uk-margin-medium-right uk-text-center" style="display: flex;align-items: center;height: 70%;">

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.literature) ? (statisticsNumbers.literature | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">Literature repositories</div>
              </div>

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.dataRepositories) ? (statisticsNumbers.dataRepositories | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">Data repositories</div>
              </div>

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.journal) ? (statisticsNumbers.journal | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">OA journals</div>
              </div>

              <div class="uk-margin-top">
                <div class="bigNumber">{{ (statisticsNumbers && statisticsNumbers.aggregators) ? (statisticsNumbers.aggregators | number) : 'N/A' }}</div>
                <div class="uk-text-uppercase">Aggregators</div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="uk-container uk-container-large uk-margin-top">
        <div class="uk-grid uk-child-width-1-5@l uk-child-width-1-5@m uk-child-width-1-2@s uk-margin-medium-left">

          <div class="uk-margin-top">
            <h3>{{ (statisticsNumbers && statisticsNumbers.publications) ? (statisticsNumbers.publications | number) : 'N/A' }}</h3>
            <div class="uk-text-uppercase text-small iconsWithText">
              <span><i class="fas fa-book fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>
              <span>publications</span>
            </div>
          </div>
          <div class="uk-margin-top">
            <h3>{{ (statisticsNumbers && statisticsNumbers.datasets) ? (statisticsNumbers.datasets | number) : 'N/A' }}</h3>
            <div class="uk-text-uppercase text-small iconsWithText">
              <span><i class="fas fa-database fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>
              <span>datasets</span>
            </div>
          </div>
          <div class="uk-margin-top">
            <h3>{{ (statisticsNumbers && statisticsNumbers.software) ? (statisticsNumbers.software | number) : 'N/A' }}</h3>
            <div class="uk-text-uppercase text-small iconsWithText">
              <span><i class="fas fa-cog fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>
              <span>software</span>
            </div>
          </div>
          <div class="uk-margin-top">
            <h3>{{ (statisticsNumbers && statisticsNumbers.usagestats) ? (statisticsNumbers.usagestats | number) : 'N/A' }}</h3>
            <div class="uk-text-uppercase text-small iconsWithText">
              <span><i class="fas fa-globe-americas fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>
              <span>metadata exchange events</span>
            </div>
          </div>
          <div class="uk-margin-top">
            <h3>{{ statisticsNumbers ? (statisticsNumbers.lastYearUsagestats.number | number) : 'N/A' }}</h3>
            <div class="uk-text-uppercase text-small iconsWithText">
              <span><i class="fas fa-globe-americas fa-2x uk-margin-small-right" style="color: #3EC8FE"></i></span>
              <span>usage statistics views & downloads ({{ statisticsNumbers ? statisticsNumbers.lastYearUsagestats.year : 'N/A' }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="uk-section uk-section-muted uk-section-large text-small" style="background: transparent url('../../../../assets/imgs/landing/kathisto%20paidaki.png') 0% 0% no-repeat padding-box;">-->
    <div class="uk-section uk-section-muted uk-padding-remove-top text-small">

      <!--LAPTOP ONLY-->
      <div class="uk-visible@l" style="position: relative; top: 86px; text-align: right;right: 195px;">
        <img src="../../../../assets/imgs/landing/kathisto%20paidaki.png" width="130">
      </div>

      <!--PAD ONLY-->
      <div class="uk-hidden@l" style="position: relative; top: 86px; text-align: right;right: 130px;">
        <img src="../../../../assets/imgs/landing/kathisto%20paidaki.png" width="130">
      </div>

      <!--LAPTOP ONLY-->
      <div class="uk-container uk-visible@l uk-container-large openAIREGuidelinesBox">
        <div>

          <div class="">
            <h5 class="uk-text-uppercase uk-text-center uk-margin-remove">
              <img class="" src="../../../../assets/imgs/landing/Arrow%20left.svg" style="margin-top: 35px; margin-right: 25px">
              <span>Follow OpenAIRE Guidelines</span>
              <img class="" src="../../../../assets/imgs/landing/Arrow%20right.svg" style="margin-top: 35px; margin-left: 25px">
            </h5>
          </div>

          <div style="border-bottom: 1px solid #BFBFBF;border-top: 1px solid #BFBFBF;margin-top: -15px;">
            <div class="uk-grid uk-child-width-1-2@l uk-child-width-1-2@m uk-grid-divider">
              <div class="uk-first-column">
                <div class="uk-margin-medium-left uk-margin-medium-top uk-margin-medium-bottom">
                  <img width="56" src="../../../../assets/imgs/landing/Guidelines%20color.svg">
                  <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">OpenAIRE Guidelines</div>
                  <p>Follow the OpenAIRE <strong>Guidelines</strong> to expose your metadata in order to integrate with OpenAIRE infrastructure. <br>Learn more:
                    <a href="https://guidelines.openaire.eu/en/latest/" target="_blank">https://guidelines.openaire.eu/en/latest/</a></p>
                  <p>Follow the OpenAIRE <strong>Usage Statistics Guidelines</strong> to get ready to track the usage activity of your data source. <br>Learn more:
                    <a href="https://openaire.github.io/usage-statistics-guidelines/" target="_blank">https://openaire.github.io/usage-statistics-guidelines/</a></p>
                </div>

              </div>

              <div class="">
                <div class="uk-margin-medium-right uk-margin-medium-top uk-margin-medium-bottom">
                  <img width="43" src="../../../../assets/imgs/landing/Data%20color.svg">
                  <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Our Data Policies</div>
                  <p>
                    <strong>Data Acquisition</strong> - How we retrieve data, how often, what processes it goes through (aggregating cleaning, transforming, inferring, de-duplicating), what are the quality checks along all data processing stages.
                  </p>
                  <p>
                    <strong>Data usage</strong> - Who is able to retrieve our data, what are the licences, what about specific SLAs. <br>Learn more:
                    <a href="https://www.openaire.eu/content-aquisition-policy" target="_blank">https://www.openaire.eu/content-aquisition-policy</a>
                  </p>
                </div>

              </div>
            </div>
          </div>


          <div class="uk-grid uk-child-width-1-2@l uk-child-width-1-2@m uk-grid-divider">
            <div class="uk-first-column">
              <div class="uk-margin-medium-left uk-margin-medium-top uk-margin-medium-bottom">
                <img width="55" src="../../../../assets/imgs/landing/Agreement%20color.svg">
                <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Terms of Agreement for Content Providers</div>
                <p>Learn more: <a href="https://www.openaire.eu/terms-of-use-for-content-providers" target="_blank">https://www.openaire.eu/terms-of-use-for-content-providers</a></p>
              </div>

            </div>

            <div class="">
              <div class="uk-margin-medium-right uk-margin-medium-top uk-margin-medium-bottom">
                <img width="38" src="../../../../assets/imgs/landing/Support%20color.svg">
                <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Support</div>
                <p>Read <strong>how to use OpenAIRE</strong> to best serve your needs. <br>Learn more: <a href="https://www.openaire.eu/guides" target="_blank">https://www.openaire.eu/guides</a></p>
                <p>Ask a question. Contact us via our <strong>ticketing system.</strong> <br>Learn more: <a href="https://www.openaire.eu/support/helpdesk" target="_blank">https://www.openaire.eu/support/helpdesk</a></p>
              </div>

            </div>
          </div>

        </div>
      </div>

      <!--PAD ONLY-->
      <div class="uk-container uk-hidden@l uk-container-small openAIREGuidelinesBox">
        <div>

          <div class="">
            <h5 class="uk-text-uppercase uk-text-center uk-margin-remove">
              <img class="" src="../../../../assets/imgs/landing/Arrow%20left.svg" style="margin-top: 35px; margin-right: 25px">
              <span>Follow OpenAIRE Guidelines</span>
              <img class="" src="../../../../assets/imgs/landing/Arrow%20right.svg" style="margin-top: 35px; margin-left: 25px">
            </h5>
          </div>

          <div style="border-bottom: 1px solid #BFBFBF;border-top: 1px solid #BFBFBF;margin-top: -15px;">
            <div class="uk-grid uk-child-width-1-2@l uk-child-width-1-2@m uk-grid-divider">
              <div class="uk-first-column">
                <div class="uk-margin-medium-left uk-margin-medium-top uk-margin-medium-bottom">
                  <img width="56" src="../../../../assets/imgs/landing/Guidelines%20color.svg">
                  <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">OpenAIRE Guidelines</div>
                  <p>Follow the OpenAIRE <strong>Guidelines</strong> to expose your metadata in order to integrate with OpenAIRE infrastructure. <br>Learn more:
                    <a href="https://guidelines.openaire.eu/en/latest/" target="_blank">https://guidelines.openaire.eu/en/latest/</a></p>
                  <p>Follow the OpenAIRE <strong>Usage Statistics Guidelines</strong> to get ready to track the usage activity of your data source. <br>Learn more:
                    <a href="https://openaire.github.io/usage-statistics-guidelines/" target="_blank">https://openaire.github.io/usage-statistics-guidelines/</a></p>
                </div>

              </div>

              <div class="">
                <div class="uk-margin-medium-right uk-margin-medium-top uk-margin-medium-bottom">
                  <img width="43" src="../../../../assets/imgs/landing/Data%20color.svg">
                  <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Our Data Policies</div>
                  <p>
                    <strong>Data Acquisition</strong> - How we retrieve data, how often, what processes it goes through (aggregating cleaning, transforming, inferring, de-duplicating), what are the quality checks along all data processing stages.
                  </p>
                  <p>
                    <strong>Data usage</strong> - Who is able to retrieve our data, what are the licences, what about specific SLAs. <br>Learn more:
                    <a href="https://www.openaire.eu/content-aquisition-policy" target="_blank">https://www.openaire.eu/content-aquisition-policy</a>
                  </p>
                </div>

              </div>
            </div>
          </div>


          <div class="uk-grid uk-child-width-1-2@l uk-child-width-1-2@m uk-grid-divider">
            <div class="uk-first-column">
              <div class="uk-margin-medium-left uk-margin-medium-top uk-margin-medium-bottom">
                <img width="55" src="../../../../assets/imgs/landing/Agreement%20color.svg">
                <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Terms of Agreement for Content Providers</div>
                <p>Learn more: <a href="https://www.openaire.eu/terms-of-use-for-content-providers" target="_blank">https://www.openaire.eu/terms-of-use-for-content-providers</a></p>
              </div>

            </div>

            <div class="">
              <div class="uk-margin-medium-right uk-margin-medium-top uk-margin-medium-bottom">
                <img width="38" src="../../../../assets/imgs/landing/Support%20color.svg">
                <div class="uk-margin-bottom uk-margin-top" style="font-weight: 600">Support</div>
                <p>Read <strong>how to use OpenAIRE</strong> to best serve your needs. <br>Learn more: <a href="https://www.openaire.eu/guides" target="_blank">https://www.openaire.eu/guides</a></p>
                <p>Ask a question. Contact us via our <strong>ticketing system.</strong> <br>Learn more: <a href="https://www.openaire.eu/support/helpdesk" target="_blank">https://www.openaire.eu/support/helpdesk</a></p>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="uk-section uk-section-muted uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-flex uk-flex-middle"
         style="background: transparent linear-gradient(0deg, #E2EEFA 20%, #F7F7F7 70%) 0% 0% no-repeat padding-box; box-sizing: border-box;">
      <!--<div style="background: transparent linear-gradient(0deg, #E2EEFA 20%, #F7F7F7 30%) 0% 0% no-repeat padding-box; box-sizing: border-box;"-->
      <!--class="uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-padding-remove-bottom uk-flex uk-flex-middle">-->
      <!--</div>-->
      <div class="uk-container uk-container-xlarge">
        <div class="uk-grid uk-flex" style="align-items: center">
          <div class="uk-width-3-4@l uk-width-3-4@m">
            <h2>We're here to help you.</h2>
            <p class="text-muted">Do you want more information? Our team is happy to assist you.</p>
            <a class="uk-margin-large-top uk-text-uppercase uk-button uk-button-primary" href="https://www.openaire.eu/contact-us/" target="_blank">Get in touch</a>
          </div>
          <div class="uk-width-1-4@l uk-width-1-4@m">
            <img src="../../../../assets/imgs/landing/contact%20us%20provide.png">
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section uk-section-muted" uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-fade&quot;,&quot;delay&quot;:false}">
      <div class="uk-container uk-container-large">
        <div class="uk-grid uk-margin-large-top uk-margin-large-bottom" uk-grid="">
          <div class="uk-width-expand@m uk-width-1-2@s uk-dark uk-grid-item-match uk-first-column explore">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Researcher?
              </h4>
              <div class="el-content uk-margin">
                Explore all OA research results. Link all your research. Build your profile
              </div>
              <p>
                <a href="https://{{ inBeta ? 'beta.' : '' }}explore.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.EXPLORE
                </a>
              </p>
            </div>
          </div>
          <div class="uk-width-expand@m uk-width-1-2@s uk-dark uk-grid-item-match connect">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Research community?
              </h4>
              <div class="el-content uk-margin">
                Use a trusted partner to share, link, disseminate and monitor your research.
              </div>
              <p>
                <a href="https://{{ inBeta ? 'beta.' : '' }}connect.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.CONNECT
                </a>
              </p>
            </div>
          </div>
          <div class="uk-width-expand@m uk-width-1-2@s uk-dark uk-grid-item-match monitor">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Research manager?
              </h4>
              <div class="el-content uk-margin">
                Use our monitoring services and easily track all relevant research results.
              </div>
              <p>
                <a href="https://monitor.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.MONITOR
                </a>
              </p>
            </div>
          </div>
          <div class="uk-width-expand@m uk-width-1-2@s uk-dark uk-grid-item-match develop">
            <div class="uk-margin uk-panel uk-scrollspy-inview uk-animation-fade" uk-scrollspy-class="" style="">
              <h4 class="el-title uk-margin uk-h4">
                Developer?
              </h4>
              <div class="el-content uk-margin">
                Get access to OpenAIRE data and capitalize on on Europe's open linked research
              </div>
              <p>
                <a href="https://develop.openaire.eu" class="el-link uk-button uk-button-default" target="_blank">
                  OpenAIRE.DEVELOP
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

