<!--<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"-->
     <!--class="modal in fade" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">-->
  <!--<div class="modal-dialog">-->
    <!--<div class="modal-content">-->
      <!--<div class="modal-body">-->
        <!--<div>-->
          <!--<div>-->
            <!--<h2 class="uk-modal-title">{{title}}</h2>-->
            <!--<ng-content></ng-content>-->
          <!--</div>-->
          <!--<div class="uk-text-right">-->
            <!--<button (click)="hideModal()" class="uk-button uk-button-default uk-margin-small-right" type="button">{{ hideModalButton }}</button>-->
            <!--<button *ngIf="confirmActionButton" (click)="confirmedAction()" class="uk-button uk-button-primary" type="button">{{confirmActionButton}}</button>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->


<div *ngIf="isModalShown" class="uk-modal {{isModalShown ? 'uk-open' : ''}}" bsModal #autoShownModal="bs-modal" (onHidden)="onHidden()" aria-hidden="false"
     [ngStyle]="{'display': isModalShown ? 'block' : 'none'}" style="overflow-y: auto">
  <div class="uk-modal-dialog" style="top: 95.5px;">
    <div class="uk-modal-header">
      <h3 class="uk-modal-title">{{title}}
        <!--<i class="material-icons" data-uk-tooltip="{pos:'top'}" title="headline tooltip"></i>-->
      </h3>
    </div>
    <ng-content></ng-content>
    <div class="uk-modal-footer uk-text-right">
      <button (click)="hideModal()" type="button" class="md-btn md-btn-flat uk-modal-close">{{ hideModalButton }}</button>
      <button *ngIf="confirmActionButton" (click)="confirmedAction()" type="button" class="md-btn md-btn-flat md-btn-flat-primary">{{confirmActionButton}}</button>
      <button *ngIf="confirmButNotCloseButton" (click)="confirmedButNotCloseAction()" type="button" class="md-btn md-btn-flat md-btn-flat-primary">{{confirmButNotCloseButton}}</button>
    </div>
  </div>
</div>


<!-- old-ui shelved Terms of Use modal version -->

<!--<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"-->
<!--     class="modal in fade" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">-->
<!--  <div class="modal-dialog">-->
<!--    <h2 class="uk-modal-title uk-margin-top uk-margin-left">{{title}}</h2>-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-body">-->
<!--        <div>-->
<!--          <ng-content></ng-content>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="uk-text-right uk-margin-bottom uk-margin-right">-->
<!--        <button (click)="hideModal()" class="uk-button uk-button-default uk-margin-small-right" type="button">{{hideModalButton}}</button>-->
<!--        <button *ngIf="confirmActionButton" (click)="confirmedAction()" class="uk-button uk-button-primary" type="button">{{confirmActionButton}}</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
