export const timezones = [
  { offset: -12.0, name: '[GMT - 12:00] United States Minor Outlying Islands' },
  { offset: -11.0, name: '[GMT - 11:00] United States, New Zealand' },
  { offset: -10.0, name: '[GMT - 10:00] Honolulu, Papeete...' },
  { offset: -9.5, name: '[GMT - 09:30] French Polynesia' },
  { offset: -9.0, name: '[GMT - 09:00] Anchorage...' },
  { offset: -8.0, name: '[GMT - 08:00] Los Angeles, Vancouver, Tijuana...' },
  { offset: -7.0, name: '[GMT - 07:00] Phoenix, Denver, Calgary, Ciudad Juárez...' },
  { offset: -6.0, name: '[GMT - 06:00] Chicago, Mexico City, Guatemala City, Tegucigalpa, Managua, Winnipeg, San José, San Salvador...' },
  { offset: -5.0, name: '[GMT - 05:00] New York, Toronto, Havana, Lima, Bogotá, Kingston...' },
  { offset: -4.0, name: '[GMT - 04:00] Santiago, Santo Domingo, Manaus, Caracas, La Paz, Asunción, Halifax...' },
  { offset: -3.5, name: '[GMT - 03:30] St. John’s...' },
  { offset: -3.0, name: '[GMT - 03:00] São Paulo, Buenos Aires, Montevideo...' },
  { offset: -2.0, name: '[GMT - 02:00] Brazil, United Kingdom...' },
  { offset: -1.0, name: '[GMT - 01:00] Praia, Ponta Delgada...' },
  { offset: 0.0, name: '[GMT] London, Dublin, Lisbon, Accra, Dakar...' },
  { offset: 1.0, name: '[GMT + 01:00] Berlin, Rome, Paris, Madrid, Vienna, Warsaw, Lagos, Kinshasa, Luanda, Algiers, Casablanca...' },
  { offset: 2.0, name: '[GMT + 02:00] Cairo, Khartoum, Johannesburg, Athens, Kiev, Bucharest, Lubumbashi, Jerusalem...' },
  { offset: 3.0, name: '[GMT + 03:00] Moscow, Istanbul, Riyadh, Baghdad, Nairobi, Minsk, Doha...' },
  { offset: 3.5, name: '[GMT + 03:30] Tehran...' },
  { offset: 4.0, name: '[GMT + 04:00] Dubai, Baku, Samara...' },
  { offset: 4.5, name: '[GMT + 04:30] Kabul...' },
  { offset: 5.0, name: '[GMT + 05:00] Karachi, Tashkent, Yekaterinburg...' },
  { offset: 5.5, name: '[GMT + 05:30] Mumbai, Delhi, Colombo...' },
  { offset: 5.75, name: '[GMT + 05:45] Kathmandu...' },
  { offset: 6.0, name: '[GMT + 06:00] Dhaka, Almaty, Omsk...' },
  { offset: 6.5, name: '[GMT + 06:30] Yangon...' },
  { offset: 7.0, name: '[GMT + 07:00] Jakarta, Bangkok, Ho Chi Minh City, Krasnoyarsk...' },
  { offset: 8.0, name: '[GMT + 08:00] Shanghai, Beijing, Hong Kong, Kuala Lumpur, Singapore, Taipei, Perth, Manila, Makassar, Irkutsk...' },
  { offset: 8.75, name: '[GMT + 08:45] Australia' },
  { offset: 9.0, name: '[GMT + 09:00] Tokyo, Seoul, Pyongyang, Ambon, Yakutsk...' },
  { offset: 9.5, name: '[GMT + 09:30] Adelaide, Darwin...' },
  { offset: 10.0, name: '[GMT + 10:00] Sydney, Melbourne, Port Moresby, Vladivostok...' },
  { offset: 10.5, name: '[GMT + 10:30] Australia (New South Wales)...' },
  { offset: 11.0, name: '[GMT + 11:00] Nouméa, Magadan...' },
  { offset: 12.0, name: '[GMT + 12:00] Auckland, Suva, Petropavlovsk-Kamchatsky...' },
  { offset: 12.75, name: '[GMT + 12:45] New Zealand (Chatham Islands)...' },
  { offset: 13.0, name: '[GMT + 13:00] Kiribati (Phoenix Islands), New Zealand (Tokelau), Samoa, Tonga' },
  { offset: 14.0, name: '[GMT + 14:00] Kiribati (Line Islands)' },
];
