export const typologies = [
  { value: '', name: '[Other] (enter name below)' },
  { value: 'CONTENTdm', name: 'CONTENTdm' },
  { value: 'Digibib', name: 'Digibib' },
  { value: 'Digital Commons', name: 'Digital Commons' },
  { value: 'DigiTool', name: 'DigiTool' },
  { value: 'DIVA', name: 'DIVA' },
  { value: 'Diva-Portal', name: 'Diva-Portal' },
  { value: 'dLibra', name: 'dLibra' },
  { value: 'Doks', name: 'Doks' },
  { value: 'Drupal', name: 'Drupal' },
  { value: 'DSpace', name: 'DSpace' },
  { value: 'Earmas', name: 'Earmas' },
  { value: 'EPrints', name: 'EPrints' },
  { value: 'ETD', name: 'ETD' },
  { value: 'ETD - db', name: 'ETD - db' },
  { value: 'Fedora', name: 'Fedora' },
  { value: 'Fez', name: 'Fez' },
  { value: 'Greenstone', name: 'Greenstone' },
  { value: 'HAL', name: 'HAL' },
  { value: 'invenio', name: 'invenio' },
  { value: 'MyCoRe', name: 'MyCoRe' },
  { value: 'Open Repository', name: 'Open Repository' },
  { value: 'OPUS', name: 'OPUS' },
  { value: 'Pica - Verbundkatalog', name: 'Pica - Verbundkatalog' },
  { value: 'Proprietary Software', name: 'Proprietary Software' },
  { value: 'PUMA', name: 'PUMA' },
  { value: 'PURE', name: 'PURE' },
  { value: 'SciELO', name: 'SciELO' },
  { value: 'SFIX', name: 'SFIX' },
  { value: 'VITAL', name: 'VITAL' },
  { value: 'VTOAI', name: 'VTOAI' },
  { value: 'WEKO', name: 'WEKO' },
  { value: 'XooNIps', name: 'XooNIps' },
  { value: 'OJS', name: 'OJS' },
  { value: 'HyperJournal', name: 'HyperJournal' },
  { value: 'ePubTk', name: 'ePubTk' },
  { value: 'GAPworks', name: 'GAPworks' },
  { value: 'DPubS', name: 'DPubS' },
  { value: 'E - Journal', name: 'E - Journal' },
];
