<aside id="sidebar_main">

  <div class="sidebar_main_header uk-margin-remove-bottom">
    <div class="uk-padding-small">
      <a [routerLink]="['/home']" class="sSidebar_hide sidebar_logo_large">
        <img class="provideLogo" src="../../../assets/imgs/OA_PROVIDE_B.png">
      </a>
      <!--<h4 class="uk-text-bold uk-margin-remove">Repository manager's</h4>-->
      <!--<span class="uk-text-large">Admin Dashboard</span>-->
    </div>
  </div>
  <!--<div class="sidebar_main_header">-->
    <!--<div class="sidebar_logo">-->
      <!--<a [routerLink]="['/home']" class="sSidebar_hide sidebar_logo_large">-->
        <!--<img class="logo_regular" src="../../../assets/imgs/OA_PROVIDE_B.png" alt="" height="" width="200"/>-->
        <!--<img class="logo_light" src="assets/img/logo_main_white.png" alt="" height="15" width="71"/>-->
      <!--</a>-->
      <!--<a [routerLink]="['/home']" class="sSidebar_show sidebar_logo_small">-->
        <!--<img class="logo_regular" src="../../../assets/imgs/OA_PROVIDE_A.png" alt="" height="32" width="32"/>-->
        <!--<img class="logo_light" src="assets/img/logo_main_small_light.png" alt="" height="32" width="32"/>-->
      <!--</a>-->
    <!--</div>-->
    <!--&lt;!&ndash;<div class="sidebar_actions">&ndash;&gt;-->
      <!--&lt;!&ndash;<select id="lang_switcher" name="lang_switcher">&ndash;&gt;-->
        <!--&lt;!&ndash;<option value="gb" selected>English</option>&ndash;&gt;-->
      <!--&lt;!&ndash;</select>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--</div>-->

  <div class="menu_section uk-margin-large-top">
    <ul>

      <li [routerLinkActive]="['current_section']" class="">
        <a [routerLink]="['/sources', 'register']">
        <!--<a [routerLink]="['/register']">-->
          <span class="menu_icon_circle_letter">R</span>
          <span class="menu_title">Register</span>
        </a>
      </li>

      <!--<li class="submenu_trigger" [ngClass]="{'act_section': checkIfCollapsed(2) }" routerLinkActive="current_section">-->
      <li class="submenu_trigger" [ngClass]="{'act_section': checkIfCollapsed(2) }">
        <a (click)="setToggle(2)">
          <span class="menu_icon_circle_letter">V</span>
          <span class="menu_title">Validator</span>
        </a>
        <ul [ngClass]="{'uk-display-block': checkIfCollapsed(2)}">
          <li routerLinkActive="act_item"><a [routerLink]="['/compatibility', 'validate']">Validate</a></li>
          <li routerLinkActive="act_item"><a [routerLink]="['/compatibility', 'browseHistory']">Validation History</a></li>
        </ul>
      </li>

      <li *ngIf="reposOfUser && reposOfUser.length>0" [routerLinkActive]="['current_section']" class="">
        <a [routerLink]="['/content', 'notifications']">
          <span class="menu_icon_circle_letter">N</span>
          <span class="menu_title">Notifications</span>
        </a>
      </li>

    </ul>
  </div>

  <div *ngIf="reposOfUser && reposOfUser.length>0" class="menu_section border_top">
    <div class="sidebar_heading">REPOSITORIES</div>
    <ul>

      <li *ngFor="let repo of visibleReposOfUser" [routerLinkActive]="['current_section']" class="">
        <!--<a [routerLink]="['/repository', '{{repo.id}}']">-->
        <a [routerLink]="['/repository/' + repo.id]">
          <span *ngIf="!repo.logoUrl" class="menu_icon_circle" style="background-image: url('../../../assets/imgs/yourLogoHere.jpg')">
          </span>
          <span *ngIf="repo.logoUrl" class="menu_icon_circle" [ngStyle]="{'background-image': 'url(' + repo.logoUrl + ')'}">
          </span>
          <!--<span class="menu_title ellipsis">{{repo.officialname}}</span>-->
          <span *ngIf="repo.officialname.length>30" class="menu_title">{{repo.officialname.substr(0,30)}}...</span>
          <span *ngIf="repo.officialname.length<=30" class="menu_title">{{repo.officialname}}</span>
        </a>
      </li>

      <li *ngIf="reposOfUser.length>5 && !allReposVisible" class="">
        <a (click)="showMoreRepos()">
          <span class="menu_icon"><i class="material-icons">keyboard_arrow_down</i></span>
          <span class="menu_title">Show more</span>
        </a>
      </li>

      <li *ngIf="reposOfUser.length>5 && allReposVisible" class="">
        <a (click)="showLessRepos()">
          <span class="menu_icon"><i class="material-icons">keyboard_arrow_up</i></span>
          <span class="menu_title">Show less</span>
        </a>
      </li>


    </ul>
  </div>

  <div *ngIf="getIsUserAdmin()" class="menu_section admin_menu_section border_top">
    <div class="sidebar_heading">ADMIN</div>
    <ul>

      <li [routerLinkActive]="['current_section']" class="">
        <a href="{{adminHomePage}}" target="_blank">
          <span class="menu_icon_circle_letter">H</span>
          <span class="menu_title">Help Texts</span>
        </a>
      </li>
      <!--<li class="">-->
      <li [routerLinkActive]="['current_section']" class="">
        <a [routerLink]="['/admin/registrations']">
          <span class="menu_icon_circle_letter">R</span>
          <span class="menu_title">Registrations</span>
        </a>
        <ul [ngClass]="{'uk-display-block': visibleAdminRepo}">
          <li class="act_item">
            <a *ngIf="adminRepository?.officialName && adminRepository.officialName.length>30" [routerLink]="['/repositoryAdmin/' + adminRepository.id]">{{adminRepository.officialName.substr(0,30)}}...</a>
            <a *ngIf="adminRepository?.officialName && adminRepository.officialName.length<=30" [routerLink]="['/repositoryAdmin/' + adminRepository.id]">{{adminRepository.officialName}}</a>
          </li>
        </ul>
      </li>
      <li [routerLinkActive]="['current_section']" class="">
        <a [routerLink]="['/admin/metrics']">
          <span class="menu_icon_circle_letter">M</span>
          <span class="menu_title">Metrics</span>
        </a>
      </li>

    </ul>
  </div>
</aside><!-- main sidebar end -->
