import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/landing/home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ForbiddenPageComponent } from './shared/reusablecomponents/403-forbidden-page.component';
import { EmptyPageComponent } from "./pages/emptypage/empty-page.component";
import { JoinComponent } from "./pages/join/join.component";
import { AboutComponent } from "./pages/landing/about/about.component";

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'join',
    component: JoinComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'emptyPage',
    component: EmptyPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'repository',
    loadChildren: () => import('./pages/repository/repository.module').then(m => m.RepositoryModule),
    // loadChildren: () => import('./pages/repository/repository.module').then(m => m.RepositoryModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'repositoryAdmin',
    loadChildren: () => import('./pages/repository/repository.module').then(m => m.RepositoryModule),
    // loadChildren: () => import('./pages/repository/repository.module').then(m => m.RepositoryModule),
    canActivate: [AuthGuardService]
  },


  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   canActivate: [AuthGuardService]
  // },
  {
    path: 'sources',
    loadChildren: () => import('./pages/sources/sources.module').then(m => m.SourcesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'compatibility',
    loadChildren: () => import('./pages/compatibility/compatibility.module').then(m => m.CompatibilityModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule),
    canActivate: [AuthGuardService]
  },
  // {
  //   path: 'getImpact',
  //   loadChildren: './pages/metrics/metrics.module#MetricsModule',
  //   canActivate: [AuthGuardService]
  // },
  {
    path: 'admin',
    loadChildren: () => import('./pages/adminPg/adminPg.module').then(m => m.AdminPgModule),
  },
  {
    path: '403-forbidden',
    component: ForbiddenPageComponent
  },
  // {
  //   path: '',
  //   redirectTo: '/home',
  //   pathMatch: 'full'
  // },
  {
    //fixme redirect to 404
    path: '**',
    redirectTo: '/403-forbidden',
    // component: ForbiddenPageComponent
  }
];


@NgModule ({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {}

