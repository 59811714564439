<div id="landingPage">
  <div class="uk-offcanvas-content">

    <!--MOBILE-->
    <div class="tm-header-mobile uk-hidden@m">
      <nav class="uk-navbar-container uk-navbar" uk-navbar="">
        <div class="uk-navbar-left">
          <a class="uk-navbar-toggle" href="#tm-mobile" uk-toggle="">
            <div uk-navbar-toggle-icon="" class="uk-navbar-toggle-icon uk-icon">
            </div>
          </a>
        </div>
        <div class="uk-navbar-center">
          <a class="uk-navbar-item uk-logo" [routerLink]="['/home']">
            <img src="../../../assets/imgs/OA_PROVIDE_A.png" alt="OpenAIRE"
                 class="uk-responsive-height"> </a>
        </div>
      </nav>
      <div id="tm-mobile" uk-offcanvas="" mode="slide" overlay="" class="uk-offcanvas">
        <div class="uk-offcanvas-bar">
          <button class="uk-offcanvas-close uk-close uk-icon" type="button" uk-close=""></button>
          <div class="uk-child-width-1-1 uk-grid" uk-grid="">
            <div>
              <div>
                <a *ngIf="!getIsUserLoggedIn()" (click)="login()">Sign in| Register</a>
                <span *ngIf="getIsUserLoggedIn()">{{getUserName()}}</span>
                <ul  class="uk-list uk-margin-top">
                  <li>
                    <a class="uk-search-input" [routerLink]="['home']" [routerLinkActive]="['uk-active']">Home</a>
                  </li>
                  <li>
                    <a class="uk-search-input" [routerLink]="['about']" [routerLinkActive]="['uk-active']">About</a>
                  </li>
                  <li>
                  <li *ngIf="getIsUserLoggedIn()">
                    <a class="uk-search-input" [routerLink]="['emptyPage']" [routerLinkActive]="['uk-active']">Dashboard</a>
                  </li>

                    <!--Sources-->
                    <!--<ul>-->
                      <!--<li><a [routerLink]="['sources', 'register']" [routerLinkActive]="['uk-active']">Register</a></li>-->
                      <!--<li><a [routerLink]="['sources', 'update']" [routerLinkActive]="['uk-active']">Update</a></li>-->
                    <!--</ul>-->
                  <!--</li>-->
                  <!--<li>-->
                    <!--Compatibility-->
                    <!--<ul>-->
                      <!--<li><a [routerLink]="['compatibility', 'validate']" [routerLinkActive]="['uk-active']">Validate</a></li>-->
                      <!--<li><a [routerLink]="['compatibility', 'browseHistory']" [routerLinkActive]="['uk-active']">Validation History</a></li>-->
                      <!--<li><a [routerLink]="['compatibility', 'monitor']" [routerLinkActive]="['uk-active']">Collection Monitor</a></li>-->
                    <!--</ul>-->
                  <!--</li>-->
                  <!--<li>-->
                    <!--Content-->
                    <!--<ul>-->
                      <!--<li><a [routerLink]="['content', 'events']" [routerLinkActive]="['uk-active']">Events</a></li>-->
                      <!--<li><a [routerLink]="['content', 'notifications']" [routerLinkActive]="['uk-active']">Notifications</a></li>-->
                    <!--</ul>-->
                  <!--</li>-->
                  <!--<li>-->
                    <!--<a class="uk-search-input" [routerLink]="['getImpact']" [routerLinkActive]="['uk-active']">Metrics</a>-->
                  <!--</li>-->
                  <!--<li *ngIf="getIsUserAdmin()">-->
                    <!--Admin-->
                    <!--<ul>-->
                      <!--<li><a href="{{adminHomePage}}" target="_blank">Help Texts</a></li>-->
                      <!--<li><a [routerLink]="['admin', 'metrics']" [routerLinkActive]="['uk-active']">Validate</a></li>s-->
                    <!--</ul>-->
                  <!--</li>-->
                  <li><a (click)="logout()">Logout</a></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--LAPTOP-->
    <div class="tm-toolbar uk-visible@m">
      <div class="uk-container uk-flex uk-flex-middle uk-container-expand">


        <div class="uk-margin-auto-left">
          <div class="uk-grid-medium uk-child-width-auto uk-flex-middle uk-grid uk-grid-stack"
               uk-grid="margin: uk-margin-small-top">
            <div class="uk-first-column">
              <div class="uk-panel inner">
                <ul class="uk-subnav uk-subnav-line">
                  <li><a class="home-icon" href="https://{{ inBeta ? 'beta.' : '' }}openaire.eu" target="_blank">
                    <span class="uk-responsive-height">
                      <svg xml:space="preserve" enable-background="new 0 0 20 20" height="20px" id="Layer_1" version="1.1" viewBox="0 0 48 48" width="20px" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M44.715,23.711c-0.381,0.382-1,0.382-1.381,0l-8.939-8.938 c-0.064-0.051-0.119-0.106-0.17-0.171l-3.83-3.829c-0.064-0.051-0.119-0.106-0.17-0.171L24,4.377L4.667,23.711 c-0.381,0.382-1,0.382-1.381,0c-0.381-0.381-0.381-1,0-1.381L23.191,2.425c0.031-0.047,0.053-0.101,0.094-0.144 C23.482,2.085,23.742,1.994,24,2c0.258-0.006,0.518,0.084,0.715,0.281c0.043,0.042,0.062,0.096,0.096,0.144L30,7.616V4.997 c0,0,0,0,0,0c0-0.552,0.447-1,1-1h4c0.277,0,0.527,0.112,0.707,0.293C35.889,4.471,36,4.721,36,4.997v8.619l8.715,8.714 C45.096,22.711,45.096,23.33,44.715,23.711z M34,5.997h-2v3.619l2,2V5.997z M10,21.997c0.552,0,1,0.448,1,1v19c0,1.105,0.896,2,2,2 h6l0,0v-13c0-0.553,0.447-1,1-1h8c0.553,0,1,0.447,1,1v13l0,0h6c1.105,0,2-0.895,2-2v-19c0-0.552,0.447-1,1-1s1,0.448,1,1v19 c0,2.209-1.791,4-4,4H13c-2.209,0-4-1.791-4-4v-19C9,22.444,9.448,21.997,10,21.997z M27,43.996v-12h-6v12l0,0H27L27,43.996z" fill="#fff" fill-rule="evenodd" id="home"></path></svg>
                    </span></a></li>
                  <li uk-tooltip="title: Search in OA. Link your research; pos: bottom-left; cls: uk-active tooltipStyling uk-width-auto">
                    <a href="https://{{ inBeta ? 'beta.' : '' }}explore.openaire.eu" target="_blank">Explore</a></li>
                  <li uk-tooltip="title: Content Provider Dashboard; pos: bottom-left; cls: uk-active tooltipStyling uk-width-auto" class="custom-provide-li">
                    <a href="https://{{ inBeta ? 'beta.' : '' }}provide.openaire.eu/" target="_blank">Provide</a></li>
                  <li uk-tooltip="title: Research Community Dashboard; pos: bottom-left; cls: uk-active tooltipStyling uk-width-auto">
                    <a href="https://{{ inBeta ? 'beta.' : '' }}connect.openaire.eu" target="_blank">Connect</a></li>
                  <li uk-tooltip="title: Monitoring Dashboard; pos: bottom-left; cls: uk-active tooltipStyling uk-width-auto">
                    <a href="https://monitor.openaire.eu" target="_blank">Monitor</a></li>
                  <li uk-tooltip="title: APIs; pos: bottom-left; cls: uk-active tooltipStyling uk-width-auto">
                    <a href="https://develop.openaire.eu" target="_blank">Develop</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="tm-header uk-visible@m tm-header-transparent" uk-header="">
      <div class="uk-navbar-container uk-sticky uk-navbar-transparent" uk-sticky="top:0" media="768"
           cls-active="uk-active uk-navbar-sticky" style="" animation="uk-animation-slide-top"
           top=".tm-header + [class*=&quot;uk-section&quot;]" cls-inactive="uk-navbar-transparent">
        <div class="uk-container uk-container-expand">
          <nav class="uk-navbar" uk-navbar="{&quot;align&quot;:&quot;left&quot;}">
            <div class="uk-navbar-left">
              <a [routerLink]="['landing']" class="uk-logo uk-navbar-item">
                <img src="../../../assets/imgs/OA_PROVIDE_B.png" alt="OpenAIRE" class="uk-responsive-height">
              </a>
            </div>

            <div class="uk-navbar-right">

              <ul class="uk-navbar-nav" >
                <li class="uk-parent" [routerLinkActive]="['uk-active']">
                  <a [routerLink]="['home']" [routerLinkActive]="['uk-active']">
                    Home
                  </a>
                </li>
                <li class="uk-parent" [routerLinkActive]="['uk-active']">
                  <a [routerLink]="['about']" [routerLinkActive]="['uk-active']">
                    About
                  </a>
                </li>
                <li *ngIf="getIsUserLoggedIn()" class="uk-parent" [routerLinkActive]="['uk-active']">
                  <a [routerLink]="['join']" [routerLinkActive]="['uk-active']">
                    Dashboard
                  </a>
                </li>

                <li *ngIf="!getIsUserLoggedIn()" class="uk-parent">
                  <a class="" (click)="login()">
                    Sign in
                    <span class="uk-margin-small-left uk-icon">
                      <svg height="20" ratio="1" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9.9" cy="6.4" fill="none" r="4.4" stroke="#000" stroke-width="1.1"></circle>
                        <path d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2" fill="none" stroke="#000" stroke-width="1.1"></path>
                      </svg>
                    </span>
                  </a>
                </li>
                <li *ngIf="getIsUserLoggedIn()" class="uk-parent">
                  <a class="" aria-expanded="false">
                    {{ getUserName() }}
                    <span class="uk-margin-small-left uk-icon">
                      <svg height="20" ratio="1" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9.9" cy="6.4" fill="none" r="4.4" stroke="#000" stroke-width="1.1"></circle>
                        <path d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2" fill="none" stroke="#000" stroke-width="1.1"></path>
                      </svg>
                    </span>
                  </a>
                  <div class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left" style="top: 80px; left: 106.55px;"
                       id="userMenu" (click)="onClick('userMenu')">
                    <div class="uk-navbar-dropdown-grid uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
                      <div class="uk-first-column">
                        <ul class="uk-nav uk-navbar-dropdown-nav">
                          <!--<ul *ngIf="getIsUserAdmin()" class="uk-nav uk-navbar-dropdown-nav">-->
                          <!--<li class="uk-nav-header" style="display: block;">Admin</li>-->
                          <!--<li style="display: block"><a href="{{adminHomePage}}" target="_blank">Help Texts</a></li>-->
                          <!--<li style="display: block"><a [routerLink]="['/admin/metrics']">Metrics</a></li>-->
                          <!--<li style="display: block" class="uk-margin-small-bottom"><a [routerLink]="['/admin/registrations']">Registrations</a></li>-->
                          <!--</ul>-->
                          <li><a class="" (click)="logout()">Log out</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

              </ul>

              <!--<div class="uk-navbar-item">-->

                <!--<ul *ngIf="!getIsUserLoggedIn()" class="uk-navbar-nav">-->
                  <!--<li class="uk-parent">-->
                    <!--<a class="" (click)="login()">-->
                      <!--Sign in-->
                      <!--<span class="uk-margin-small-left uk-icon">-->
                      <!--<svg height="20" ratio="1" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">-->
                        <!--<circle cx="9.9" cy="6.4" fill="none" r="4.4" stroke="#000" stroke-width="1.1"></circle>-->
                        <!--<path d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2" fill="none" stroke="#000" stroke-width="1.1"></path>-->
                      <!--</svg>-->
                    <!--</span>-->
                    <!--</a>-->
                  <!--</li>-->
                <!--</ul>-->

                <!--<ul *ngIf="getIsUserLoggedIn()" class="uk-navbar-nav">-->
                  <!--<li class="uk-parent">-->
                    <!--<a class="" aria-expanded="false">-->
                      <!--{{ getUserName() }}-->
                      <!--<span class="uk-margin-small-left uk-icon">-->
                      <!--<svg height="20" ratio="1" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">-->
                        <!--<circle cx="9.9" cy="6.4" fill="none" r="4.4" stroke="#000" stroke-width="1.1"></circle>-->
                        <!--<path d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2" fill="none" stroke="#000" stroke-width="1.1"></path>-->
                      <!--</svg>-->
                    <!--</span>-->
                    <!--</a>-->
                    <!--<div class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left" style="top: 80px; left: 106.55px;"-->
                         <!--id="userMenu" (click)="onClick('userMenu')">-->
                      <!--<div class="uk-navbar-dropdown-grid uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">-->
                        <!--<div class="uk-first-column">-->
                          <!--<ul class="uk-nav uk-navbar-dropdown-nav">-->
                            <!--&lt;!&ndash;<ul *ngIf="getIsUserAdmin()" class="uk-nav uk-navbar-dropdown-nav">&ndash;&gt;-->
                              <!--&lt;!&ndash;<li class="uk-nav-header" style="display: block;">Admin</li>&ndash;&gt;-->
                              <!--&lt;!&ndash;<li style="display: block"><a href="{{adminHomePage}}" target="_blank">Help Texts</a></li>&ndash;&gt;-->
                              <!--&lt;!&ndash;<li style="display: block"><a [routerLink]="['/admin/metrics']">Metrics</a></li>&ndash;&gt;-->
                              <!--&lt;!&ndash;<li style="display: block" class="uk-margin-small-bottom"><a [routerLink]="['/admin/registrations']">Registrations</a></li>&ndash;&gt;-->
                            <!--&lt;!&ndash;</ul>&ndash;&gt;-->
                            <!--<li><a class="" (click)="logout()">Log out</a></li>-->
                          <!--</ul>-->
                        <!--</div>-->
                      <!--</div>-->
                    <!--</div>-->
                  <!--</li>-->
                <!--</ul>-->

              <!--</div>-->


            </div>

          </nav>
        </div>
      </div>

      <div class="uk-sticky-placeholder" style="height: 84px; margin: 0px;" hidden="hidden"></div>
    </div>

  </div>
</div>

