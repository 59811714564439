<!--MOBILE & PAD PORTRAIT-->
<div class="uk-hidden@m">
  <div class="provideLanding">

    <div class="image-front-topbar  uk-section-default"
         uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-fade&quot;,&quot;delay&quot;:false}" tm-header-transparent="light">
      <!--<div style="background-image: url('../../../../assets/imgs/landing/Group 1149.svg'); background-color: rgb(255, 255, 255); box-sizing: border-box; min-height: calc(-101.35px + 100vh);"-->
      <!--<div style="background-image: url('../../../../assets/imgs/landing/Group 1149.svg'); background-color: rgb(255, 255, 255); box-sizing: border-box; min-height: calc(-101.35px + 100vh);"-->
      <div style="background: transparent linear-gradient(0deg, #F7F7F7 20%, #E2EEFA 30%) 0% 0% no-repeat padding-box; box-sizing: border-box; min-height: calc(-101.35px + 100vh);"
           class="uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-padding-remove-bottom uk-flex uk-flex-middle" uk-height-viewport="offset-top: true;offset-bottom: 20">
        <div class="uk-width-1-1">
          <!--<div class="uk-margin-medium uk-grid uk-grid-stack" uk-grid="">-->
            <!--<div class="uk-width-1-1@m">-->
            <!--</div>-->
          <!--</div>-->
          <div class="uk-container uk-container-large uk-margin-large aboutPage">
            <!--<div class="uk-sticky-placeholder" style="height: 84px; margin: 0px;"></div>-->
            <div class="uk-grid uk-flex-middle uk-grid uk-grid-stack uk-margin-small-left" uk-grid="">

              <div class="el-overlay uk-panel uk-width-1-2@l uk-width-1-2@m uk-dark">
                <div class="uk-margin-left uk-margin-right">
                  <h1 class="el-title uk-margin">Capture the essence</h1>
                  <div class="uk-margin-medium-top">
                    <p class="text-muted">The OpenAIRE Content Provider Dashboard is a one-stop-shop web service where data providers (repository, data archive, journal, aggregator, CRIS system) interact with OpenAIRE. It provides the front-end access to many of OpenAIRE’s backend services.</p>
                  </div>
                </div>

                <!--<p><a (click)="goToPage('/dashboard')" class="el-link uk-button uk-button-primary">Start here</a></p>-->
              </div>

              <div class="el-overlay uk-panel uk-width-1-2@l uk-width-1-2@m uk-dark" style="text-align: right">
                <div class="uk-margin-left uk-margin-right">
                  <img style="box-shadow: 0px 3px 8px #00000033;" src="../../../../assets/imgs/landing/about-us-dashboard-2.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section-secondary uk-section uk-section-small uk-padding-remove-bottom uk-padding-remove-top uk-preserve-color">

      <div style="position: relative; top: 120px">
        <div style="text-align: center">
          <img src="../../../../assets/imgs/landing/_0002_Group-2.png" width="214">
        </div>
      </div>

      <div class="uk-container uk-container-expand uk-margin-large-top">

        <div class="validationAndRegistrationSection uk-padding">

          <div class="uk-container uk-container-medium uk-container-center uk-margin-large-top">
            <div class="" style="text-align: center">
              <h2 class="">Validation & Registration</h2>
              <p>
                The OpenAIRE Validator service allows to test your repository’s compatibility with the OpenAIRE Guidelines. If validation succeeds the data source can be registered for regular aggregation and indexing in OpenAIRE. OpenAIRE allows for registration of institutional and thematic repositories registered in OpenDOAR, research data repositories registered in re3data, individual e-Journals, CRIS, aggregators and publishers.
              </p>
              <p class="uk-margin-remove-bottom">
                The Validator service includes two main steps to perform the aggregation and indexing of your datasource in OpenAIRE:
              </p>
            </div>
          </div>

          <div class="uk-container uk-container-large uk-container-center">
            <div class="uk-grid uk-child-width-1-2@l uk-child-width-1-2@m">

              <div class="uk-margin-medium-top">
                <!--<div>-->
                <!--<div style="position: relative; top: 10px; text-align: right;right: 82px;">-->
                  <!--<img src="../../../../assets/imgs/landing/arrow5.svg">-->
                <!--</div>-->
                <h3 class="uk-margin-remove-top">1st: Validate your datasource</h3>
                <p>Test the compatibility of your repository/journal or aggregator to the OpenAIRE guidelines. If validation
                  succeeds the data source can be registered for regular aggregation and indexing in OpenAIRE.</p>
                <!--<div style="position: relative; top: -10px; text-align: right;right: -145px;">-->
                  <!--<img src="../../../../assets/imgs/landing/arrow7.svg">-->
                <!--</div>-->
              </div>

              <div class="uk-margin-medium-top">
                <h3>2nd: Register your datasource</h3>
                <p>Register your literature repository, data repository, journal or aggregator in OpenAIRE.</p>
                <p>Once registered in OpenAIRE, the data source manager are able to use the Provide functionalities to validate their data source compatibility with the OpenAIRE Guidelines, to get notification to enrich their research results with additional or missing metadata, and to track the data source usage activity.</p>
              </div>
            </div>
          </div>

          <div class="uk-container uk-container-large uk-container-center uk-margin-large-top">
            <div class="uk-text-center">
              <p>Learn more: <a href="https://www.openaire.eu/validator-registration-guide" target="_blank">https://www.openaire.eu/validator-registration-guide</a></p>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="uk-section-secondary uk-section uk-section-large uk-padding-remove-top uk-padding-remove-bottom uk-preserve-color">
      <div class="uk-container uk-container-expand" style="position: relative; top: -260px">

        <div class="uk-grid">

          <div class="uk-width-1-2@l uk-width-1-2@m">

            <div style="position: relative; top: 255px">
              <div style="text-align: center">
                <img src="../../../../assets/imgs/landing/_0001_Group-3.png" width="158">
              </div>
            </div>

            <!--<div style="position: relative; top: -4px; left: 550px;">-->
              <!--<img src="../../../../assets/imgs/landing/arrow3.svg">-->
            <!--</div>-->
            <div class="enrichmentsSection uk-padding" style="margin-top: 175px">
              <!--<div style="text-align: center">-->
              <!--<img src="../../../../assets/imgs/landing/_0001_Group-3.png" width="158">-->
              <!--</div>-->
              <div class="uk-margin-large-top" style="text-align: center">
                <h2 class="">Enrichments</h2>
                <p>Content enrichment is a powerful tool that enables Content providers to enrich research artifacts with additional metadata, through the OpenAIRE Notification Broker Service.</p>
                <p>Learn more: <a href="https://www.openaire.eu/content-enrichment-guide" target="_blank">https://www.openaire.eu/content-enrichment-guide</a></p>
              </div>
            </div>
            <!--<div style="position: relative; top: -50px; text-align: right;right: -40px;">-->
              <!--<img src="../../../../assets/imgs/landing/arrow4.svg">-->
            <!--</div>-->
          </div>


          <div class="uk-width-1-2@l uk-width-1-2@m uk-margin-xlarge" style="position: relative; top: -290px">

            <div style="position: relative; top: 275px">
              <div style="text-align: center">
                <img src="../../../../assets/imgs/landing/_0003_Group-1.png" width="258">
              </div>
            </div>

            <div class="measureSection uk-padding">
              <!--<div style="text-align: center">-->
              <!--<img src="../../../../assets/imgs/landing/_0003_Group-1.png" width="258">-->
              <!--</div>-->
              <div class="uk-margin-large-top" style="text-align: center">
                <h2 class="">Usage Statistics</h2>
                <p>OpenAIRE’s Usage Statistic service uses the Matomo Open Source Analytics platform (matomo.org) to track usage activity. It collects and analyzes usage data from the network of OpenAIRE data providers and exploits usage metrics like downloads and metadata views.</p>
                <p>Learn more: <a href="https://www.openaire.eu/guides-usage-statistics" target="_blank">https://www.openaire.eu/guides-usage-statistics</a></p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="uk-section uk-section-large uk-section-muted uk-background-norepeat uk-background-cover uk-background-top-center
  uk-background-fixed uk-section uk-flex uk-flex-middle"
         style="background: transparent linear-gradient(0deg, #E2EEFA 20%, #F7F7F7 70%) 0% 0% no-repeat padding-box; box-sizing: border-box; position: relative; top: -450px">

      <div class="uk-container uk-container-xsmall uk-container-center uk-margin-left uk-margin-right">
        <div class="uk-grid uk-flex" style="display: block; text-align: center">
          <div class="">
            <h2>Contact us to help you.</h2>
            <p class="text-muted">Are you looking for more? Get in touch with our team and let us help you.</p>
            <a class="uk-margin-large-top uk-text-uppercase uk-button uk-button-primary" href="https://www.openaire.eu/contact-us/" target="_blank">Get in touch</a>
          </div>
          <div class="uk-margin-top">
            <img src="../../../../assets/imgs/landing/_0000_Group-4.png" width="153">
          </div>
        </div>
      </div>

      <!--<div class="uk-container uk-container-large">-->
        <!--<div class="uk-grid uk-flex" style="align-items: center">-->
          <!--<div class="uk-width-1-3@l uk-width-1-3@m">-->
            <!--<img src="../../../../assets/imgs/landing/_0000_Group-4.png">-->
          <!--</div>-->
          <!--<div class="uk-width-2-3@l uk-width-2-3@m">-->
            <!--<div class="uk-margin-large-left">-->
              <!--<h2>Contact us to help you.</h2>-->
              <!--<p class="text-muted">Are you looking for more? Get in touch with our team and let us help you.</p>-->
              <!--<button class="uk-margin-large-top uk-text-uppercase uk-button uk-button-primary">Get in touch</button>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    </div>

  </div>
</div>

<!--LAPTOP & PAD LANDSCAPE-->
<div class="uk-visible@m">
  <div class="provideLanding">

    <div class="image-front-topbar  uk-section-default"
         uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-fade&quot;,&quot;delay&quot;:false}" tm-header-transparent="light">
      <div style="background-color: #F7F7F7; box-sizing: border-box; min-height: calc(-101.35px + 100vh);"
           class="uk-background-norepeat uk-background-cover uk-background-top-center uk-background-fixed uk-section uk-padding-remove-bottom uk-flex uk-flex-middle" uk-height-viewport="offset-top: true;offset-bottom: 20">
        <div class="uk-width-1-1">
          <div class="uk-margin-medium uk-grid uk-grid-stack" uk-grid="">
            <div class="uk-width-1-1@m">
            </div>
          </div>
          <div class="uk-container uk-container-expand uk-margin-large aboutPage">
            <div class="uk-sticky-placeholder" style="height: 84px; margin: 0px;"></div>
            <div class="uk-grid-large uk-flex-middle uk-grid uk-grid-stack uk-margin-large-left" uk-grid="">

              <div class="el-overlay uk-panel uk-width-1-2@l uk-width-1-2@m uk-dark">
                <div class="uk-margin-left uk-margin-right">
                  <h1 class="el-title uk-margin">Capture the essence</h1>
                  <div class="uk-margin-medium-top">
                    <p class="text-muted">The OpenAIRE Content Provider Dashboard is a one-stop-shop web service where data providers (repository, data archive, journal, aggregator, CRIS system) interact with OpenAIRE. It provides the front-end access to many of OpenAIRE’s backend services.</p>
                  </div>
                </div>

                <!--<p><a (click)="goToPage('/dashboard')" class="el-link uk-button uk-button-primary">Start here</a></p>-->
              </div>

              <div class="el-overlay uk-panel uk-width-1-2@l uk-width-1-2@m uk-dark" style="text-align: right">
                <div class="uk-margin-left uk-margin-right">
                  <img style="box-shadow: 0px 3px 8px #00000033;" src="../../../../assets/imgs/landing/about-us-dashboard-2.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section-secondary uk-section uk-section-small uk-padding-remove-bottom uk-padding-remove-top uk-preserve-color">

      <div style="position: relative; top: 150px">
        <div style="text-align: center">
          <img src="../../../../assets/imgs/landing/_0002_Group-2.png" width="214">
        </div>
      </div>

      <div class="uk-container uk-container-expand uk-margin-large-top">

        <div class="validationAndRegistrationSection uk-padding">

          <div class="uk-container uk-container-medium uk-container-center uk-margin-large-top">
            <div class="" style="text-align: center">
              <h2 class="">Validation & Registration</h2>
              <p>
                The OpenAIRE Validator service allows to test your repository’s compatibility with the OpenAIRE Guidelines. If validation succeeds the data source can be registered for regular aggregation and indexing in OpenAIRE. OpenAIRE allows for registration of institutional and thematic repositories registered in OpenDOAR, research data repositories registered in re3data, individual e-Journals, CRIS, aggregators and publishers.
              </p>
              <p class="uk-margin-remove-bottom">
                The Validator service includes two main steps to perform the aggregation and indexing of your datasource in OpenAIRE:
              </p>
            </div>
          </div>

          <div class="uk-container uk-container-large uk-container-center">
            <div class="uk-grid uk-child-width-1-2@l uk-child-width-1-2@m">

              <div class="paddingLargeLeft paddingLargeRight">
                <!--<div>-->
                <div style="position: relative; top: 10px; text-align: right;right: 82px;">
                  <img src="../../../../assets/imgs/landing/arrow5.svg">
                </div>
                <h3 class="uk-margin-remove-top">1st: Validate your datasource</h3>
                <p>Test the compatibility of your repository/journal or aggregator to the OpenAIRE guidelines. If validation
                  succeeds the data source can be registered for regular aggregation and indexing in OpenAIRE.</p>
                <div style="position: relative; top: -10px; text-align: right;right: -145px;">
                  <img src="../../../../assets/imgs/landing/arrow7.svg">
                </div>
              </div>

              <div class="padding paddingLargeLeft paddingLargeRight uk-margin-xlarge">
                <h3>2nd: Register your datasource</h3>
                <p>Register your literature repository, data repository, journal or aggregator in OpenAIRE.</p>
                <p>Once registered in OpenAIRE, the data source manager are able to use the Provide functionalities to validate their data source compatibility with the OpenAIRE Guidelines, to get notification to enrich their research results with additional or missing metadata, and to track the data source usage activity.</p>
              </div>
            </div>
          </div>

          <div class="uk-container uk-container-large uk-container-center uk-margin-large-top">
            <div class="uk-text-center">
              <p>Learn more: <a href="https://www.openaire.eu/validator-registration-guide" target="_blank">https://www.openaire.eu/validator-registration-guide</a></p>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="uk-section-secondary uk-section uk-section-large uk-padding-remove-top uk-padding-remove-bottom uk-preserve-color">
      <div class="uk-container uk-container-expand" style="position: relative; top: -260px">

        <div class="uk-grid">

          <div class="uk-width-1-2@l uk-width-1-2@m">

            <div style="position: relative; top: 275px">
              <div style="text-align: center">
                <img src="../../../../assets/imgs/landing/_0001_Group-3.png" width="158">
              </div>
            </div>

            <div style="position: relative; top: -4px; left: 550px;">
              <img src="../../../../assets/imgs/landing/arrow3.svg">
            </div>
            <div class="enrichmentsSection uk-padding" style="margin-top: -23px">
              <!--<div style="text-align: center">-->
                <!--<img src="../../../../assets/imgs/landing/_0001_Group-3.png" width="158">-->
              <!--</div>-->
              <div class="uk-margin-large-top" style="text-align: center">
                <h2 class="">Enrichments</h2>
                <p>Content enrichment is a powerful tool that enables Content providers to enrich research artifacts with additional metadata, through the OpenAIRE Notification Broker Service.</p>
                <p>Learn more: <a href="https://www.openaire.eu/content-enrichment-guide" target="_blank">https://www.openaire.eu/content-enrichment-guide</a></p>
              </div>
            </div>
            <div style="position: relative; top: -50px; text-align: right;right: -40px;">
              <img src="../../../../assets/imgs/landing/arrow4.svg">
            </div>
          </div>


          <div class="uk-width-1-2@l uk-width-1-2@m uk-margin-xlarge">

            <div style="position: relative; top: 275px">
              <div style="text-align: center">
                <img src="../../../../assets/imgs/landing/_0003_Group-1.png" width="258">
              </div>
            </div>

            <div class="measureSection uk-padding">
              <!--<div style="text-align: center">-->
                <!--<img src="../../../../assets/imgs/landing/_0003_Group-1.png" width="258">-->
              <!--</div>-->
              <div class="uk-margin-large-top" style="text-align: center">
                <h2 class="">Usage Statistics</h2>
                <p>OpenAIRE’s Usage Statistic service uses the Matomo Open Source Analytics platform (matomo.org) to track usage activity. It collects and analyzes usage data from the network of OpenAIRE data providers and exploits usage metrics like downloads and metadata views.</p>
                <p>Learn more: <a href="https://www.openaire.eu/guides-usage-statistics" target="_blank">https://www.openaire.eu/guides-usage-statistics</a></p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="uk-section uk-section-large uk-section-muted uk-background-norepeat uk-background-cover uk-background-top-center
  uk-background-fixed uk-section uk-flex uk-flex-middle uk-padding-remove-top"
         style="background: transparent linear-gradient(0deg, #E2EEFA 20%, #F7F7F7 70%) 0% 0% no-repeat padding-box; box-sizing: border-box;">
      <div class="uk-container uk-container-large">
        <div class="uk-grid uk-flex" style="align-items: center">
          <div class="uk-width-1-3@l uk-width-1-3@m">
            <img src="../../../../assets/imgs/landing/_0000_Group-4.png">
          </div>
          <div class="uk-width-2-3@l uk-width-2-3@m">
            <div class="uk-margin-large-left">
              <h2>Contact us to help you.</h2>
              <p class="text-muted">Are you looking for more? Get in touch with our team and let us help you.</p>
              <a class="uk-margin-large-top uk-text-uppercase uk-button uk-button-primary" href="https://www.openaire.eu/contact-us/" target="_blank">Get in touch</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
