<div>
  <div class="uk-width-1-1 uk-grid">
    <div class="uk-width-1-1">
      <div *ngIf="errorMessage" class="uk-alert uk-alert-danger">{{ errorMessage }}</div>
      <div *ngIf="warningMessage" class="uk-alert uk-alert-warning">{{ warningMessage }}</div>
      <div *ngIf="loadingMessage" class="loading-big">
        <div class="loader-big" style="text-align: center; padding-top: 170px; color: rgb(47, 64, 80); font-weight: bold;">
          {{ loadingMessage }}
        </div>
        <div class="transparentFilm"></div>
      </div>


      <div id="list_grid" *ngIf="reposOfUser && reposOfUser.length>0"
           class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l {{tilesView ? 'grid_view' : 'list_view'}}">
        <div *ngFor="let repo of reposOfUser" class="uk-margin-medium-top">

          <a class="md-card" [routerLink]="[getLinkToNext(repo)]">
            <div >
              <div class="md-card-head uk-text-center uk-position-relative">
                <div *ngIf="parent === 'metrics'" class="{{getBadgeCSS(repo)}} uk-position-absolute uk-position-top-left uk-margin-left uk-margin-top">
                  {{getBadgeText(repo)}}
                </div>
                <img *ngIf="!repo.logoUrl" class="md-card-head-img dense-image dense-ready" src="../../../assets/imgs/yourLogoHere.jpg" alt="" data-dense-cap="2">
                <img *ngIf="repo.logoUrl" class="md-card-head-img dense-image dense-ready" src="{{repo.logoUrl}}" alt="" data-dense-cap="2">
              </div>
              <div class="md-card-content">
                <h4 class="heading_c uk-margin-bottom">{{repo.officialname}}</h4>
                <p>{{repo.description}}</p>
                <!--<a class="md-btn md-btn-small md-btn-success" [routerLink]="[getLinkToNext(repo)]">Add to Cart</a>-->
              </div>
            </div>
          </a>

        </div>
      </div>

    </div>

  </div>
</div>
